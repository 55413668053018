import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import CustomerScreen from '../../screens/CustomerScreen';
import CustomerDetailsScreen from '../../screens/CustomerDetailsScreen';
import CampaignDetailScreen from '../../screens/CampaignDetailScreen';
import UsersScreen from '../../screens/UsersScreen';
import CampaignScreen from '../../screens/CampaignScreen';
import RecipientsScreen from '../../screens/RecipientsScreen';
import LoadingScreen from '../../screens/LoadingScreen';
import auth from '../auth';
import AuthWrapper from '../AuthWrapper';
import { permissionNaming } from '../../services/PermissionWrapper/permissions';
import ProtectPermissionsRoute from './ProtectPermissionsRoute';
import GuestRoutes from './GuestRoutes';
import UserProfile from '../../screens/UserProfile';
import ResetPassword from '../../screens/ResetPassword'
import paths from './paths';
import { useEffect } from 'react';

const RoutesComponent = () => {
  const user = useSelector(({ auth: { user } }) => user);
  const tableSearch = useSelector(({ table: { search } }) => search);
  useEffect(() => {
    if (tableSearch) {
      localStorage.setItem('tableData', JSON.stringify(tableSearch));
    }
  }, [tableSearch])
  if (!auth.isSignedIn()) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <GuestRoutes />
      </Suspense>
    )
  };

  return (
    <Routes>
      <Route element={<AuthWrapper />}>
        <Route path={paths.campaigns} element={<CampaignScreen />} />
        <Route path={paths.profile} element={<UserProfile />} />
        <Route path={`${paths.campaigns}/:id`} element={
          <ProtectPermissionsRoute permId={permissionNaming.campaignEdit}>
            <CampaignDetailScreen />
          </ProtectPermissionsRoute>}
        />
        <Route path={paths.customers} element={
          <ProtectPermissionsRoute permId={permissionNaming.customersTable}>
            <CustomerScreen />
          </ProtectPermissionsRoute>}
        />
        <Route path={`${paths.customers}/:id`} element={
          <ProtectPermissionsRoute permId={permissionNaming.customerEdit}>
            <CustomerDetailsScreen />
          </ProtectPermissionsRoute>}
        />
        <Route path={paths.recipients} element={
          <ProtectPermissionsRoute permId={permissionNaming.recipientsTable}>
            <RecipientsScreen />
          </ProtectPermissionsRoute>}
        />
        <Route path={paths.users} element={
          <ProtectPermissionsRoute permId={permissionNaming.usersAndModeratorsTable}>
            <UsersScreen />
          </ProtectPermissionsRoute>}
        />
        <Route path="/*" element={<Navigate to={paths.campaigns} replace />} />
      </Route>
      <Route path={paths.setpassword} element={<ResetPassword />} />
    </Routes>
  )
};


export default RoutesComponent;
