import React from 'react';
import { Box, Typography, Link, Divider } from '@mui/material';
import TopBar from '../components/TopBar';
import DialogWindow from '../components/DialogWindow';

const MainLayout = ({ children }) => (
  <Box backgroundColor='background.default'>
    <TopBar />
    <Box mx={4} my='60px'>
      {children}
    </Box>
    <Box
      position="fixed"
      backgroundColor='background.default'
      sx={{ height: '60px', top: 'auto', bottom: 0, left: 0, right: 0 }}>
      <Box display='flex' flexDirection='column' pl={4}>
        <DialogWindow />
        <Typography variant="body3" color='primary' >Contact us <Typography variant="body3" color='secondary'>technical.support@echovox.com</Typography></Typography>
        <Typography variant="body3" color='primary'>Copyright &#169; {new Date().getFullYear()} Echovox. All Rights Reserved.
          This site is protected by reCAPTCHA and the <span>Google Privacy Policy</span> and <span>Terms of Service</span> apply.
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default MainLayout;
