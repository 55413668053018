import React, { useState } from 'react';
import {
  Box, Divider, Typography, AppBar, IconButton, Popover, Paper, List, ListItemButton,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Logout, ManageAccountsRounded, SupervisorAccountOutlined } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { permissionNaming } from '../../services/PermissionWrapper/permissions';

import PermissionWrapper from '../../services/PermissionWrapper';
import { logOut } from '../../redux/actions/auth';
import { useNavigate } from 'react-router-dom';
import paths from '../../services/Routes/paths';
import './TopBar.css';


const TopBar = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const handlePopover = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <AppBar position="fixed" elevation={0} style={{ backgroundColor: '#FBFCFC', }} >
      <Box display='flex' sx={{ height: '60px' }} flexDirection='row' justifyContent='space-between' py={2} px={3} alignItems='center'>
        <Box>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box width='140px' px={2} className='topLogo' />
            <Box px={2}>
              <Typography variant='body1'><NavLink end className={({ isActive }) => (isActive ? " activeNavLink" : 'navLink')} to={paths.campaigns}>Campaigns</NavLink></Typography>
            </Box>
            <PermissionWrapper permId={'customersNavLink'}>
              <Box px={2}>
                <Typography variant='body1'><NavLink end className={({ isActive }) => (isActive ? " activeNavLink" : 'navLink')} to={paths.customers}>Customers</NavLink></Typography>
              </Box>
            </PermissionWrapper>
            <PermissionWrapper permId={'recipientsNavLink'}>
              <Box pl={2} pr={4}>
                <Typography variant='body1'><NavLink className={({ isActive }) => (isActive ? " activeNavLink" : 'navLink')} to={paths.recipients}>Recipients</NavLink></Typography>
              </Box>
            </PermissionWrapper>
            {/* <InfoOutlined fontSize='16px' color='primary' />
              <Box px={0.5}>
                <Typography color='primary' variant='body1'>User Manual</Typography>
              </Box> */}
          </Box>
        </Box>
        <Box >
          <Box display='flex' flexDirection='row' alignItems='center'>
            <PermissionWrapper permId='deactivateNotification'>
              <NotificationsIcon fontSize='16px' color='secondary' />
              <Box pl={1} pr={2}>
                <Typography variant='body2' color='secondary'>The customer is deactivated. Now you have restricted access.</Typography>
              </Box>
            </PermissionWrapper>
            <Box>
              <IconButton onClick={handlePopover} sx={{ backgroundColor: '#F1F1F1' }} ><ManageAccountsRounded color='primary' /></IconButton>
            </Box>
          </Box>
          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            id='popperMoreOptions'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Paper elevation={2} px={2}>
              <List>
                <ListItemButton
                  onClick={() => { navigate(paths.profile); setAnchorEl(null) }}>
                  <ManageAccountsRounded color='primary' />
                  <Typography color='primary' variant='body2' sx={{ paddingX: '5px' }}>User profile</Typography>
                </ListItemButton>
                <PermissionWrapper permId={permissionNaming.usersAndModeratorsTable} >
                  <ListItemButton
                    onClick={() => { navigate(paths.users); setAnchorEl(null) }}>
                    <SupervisorAccountOutlined color='primary' />
                    <Typography color='primary' variant='body2' sx={{ paddingX: '5px' }}>Users</Typography>
                  </ListItemButton>
                </PermissionWrapper>
                <ListItemButton
                  onClick={() => dispatch(logOut())}>
                  <Logout color='primary' />
                  <Typography color='primary' variant='body2' sx={{ paddingX: '5px' }}>Log out</Typography>
                </ListItemButton>
              </List>
            </Paper>
          </Popover>
        </Box>
      </Box>
      <Divider />
    </AppBar >
  )
}

export default TopBar;
