import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Tooltip, TextField, Button, Paper, Autocomplete } from '@mui/material';
import { HelpCenter, InfoOutlined } from '@mui/icons-material';

const InputWithChips = ({
  id,
  label,
  selectOptions,
  tooltip,
  info,
  required,
  button,
  width,
  value,
  onChange,
  hasErrors,
  defaultValue,
  idDisabled,
  showTooltip,
  optionLabelKey,
}) => {
  const [curOption, setCurOption] = useState([]);

  useEffect(() => {
    if (selectOptions?.length) {
      if (value) {
        const newCurOption = selectOptions.filter((u) => value.includes(u.id));
        setCurOption(newCurOption);
      }
      else {
        setCurOption(null);
      }
    }

  }, [value, selectOptions]);

  const dispatch = useDispatch();
  return (
    <>
      <Box pb={2} width={width} sx={{ position: 'relative' }}>
        <Box display='flex' alignItems='center'>
          <Autocomplete
            fullWidth
            PaperComponent={({ children }) => (
              <Paper style={{ marginBottom: 10 }}>{children}</Paper>
            )}
            multiple
            id="tags-outlined"
            options={selectOptions}
            getOptionLabel={(option) => option?.[optionLabelKey]}
            filterSelectedOptions
            value={curOption}
            onChange={(event, newValue) => {

              onChange(newValue.map((it) => it.id))
            }}
            renderOption={(props, option) => {
              const newProps = { ...props, key: option.id }
              return < li {...newProps}>{option[optionLabelKey]}</li>
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
              />
            )}
          />
          {/* <Autocomplete
            disabled={idDisabled}
            PaperComponent={({ children }) => (
              <Paper style={{ marginBottom: 10 }}>{children}</Paper>
            )}
            getOptionLabel={(option) => option?.[optionLabelKey]}
            options={selectOptions}
            fullWidth
            value={curOption}
            onChange={(event, newValue) => {
              onChange(newValue?.id || '')
            }}
            renderOption={(props, option) => {
              const newProps = { ...props, key: option.id }
              return < li {...newProps}>{option[optionLabelKey]}</li>
            }}
            renderInput={(params) => <TextField
              {...params}
              required={required}
              label={label}
              error={hasErrors}
              helperText={hasErrors ? tooltip : ''}
            />}
          /> */}
          {button && <Box sx={{ minWidth: '105px' }} ml={1}><Button variant='outlined' color='text' onClick={() => dispatch(button.action())} >{button.name}</Button></Box>}
        </Box>

        {tooltip && showTooltip && <Tooltip sx={{ position: 'absolute', right: -24, top: 0 }} title={tooltip} placement="top" arrow>< HelpCenter fontSize='small' /></Tooltip>}

        {info && <Box display='flex' pt={1} alignItems='center' >
          <InfoOutlined color='primary' fontSize='small' />
          <Box pl={1}>{info}</Box>
        </Box>}
      </Box>
    </>
  )

}


export default InputWithChips;