export const sendingTime = [
  { id: 'delay', name: 'Start a campaign in 5 min' },
  { id: 'schedule', name: 'Schedule the campaign' },
  { id: 'doNotSend', name: 'Do not start the campaign after creation' },
]

export const billingModel = [
  { id: 'POSTPAID', name: 'Postpaid' },
  { id: 'PREPAID', name: 'Prepaid' },
]
export const statuses = [
  { id: 'CREATED', name: 'Created' },
  { id: 'SCHEDULED', name: 'Scheduled' },
  { id: 'DRAFT', name: 'Draft' },
  { id: 'RUNNING', name: 'Running' },
  { id: 'PAUSED', name: 'Paused' },
  { id: 'COMPLETED', name: 'Completed' },
  { id: 'CANCELED', name: 'Canceled' },
  { id: 'REJECTED', name: 'Rejected' },
];

export const dateFilterCondition = [
  { id: 'between', name: 'Between' },
  { id: 'exact', name: 'One day' },
  { id: 'form', name: 'From' },
  { id: 'to', name: 'Before' },
]
