import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { Outlet } from 'react-router-dom';
import auth from '../auth';
import MainLayout from '../../layouts/MainLayout';

import { initAuth } from '../../redux/actions/auth';


function AuthWrapper({ children }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(({ ui: { authLoading } }) => authLoading);
  const user = useSelector(({ auth: { user } }) => user);

  useEffect(() => {
    auth.setAxiosInterceptors();
    dispatch(initAuth())
  }, []);

  if (isLoading || !user?.role) return <LinearProgress color='secondary' />
  return <MainLayout><Outlet /></MainLayout>;
}

export default AuthWrapper;
