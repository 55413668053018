import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { MoreVert, Clear, ContentCopyRounded } from '@mui/icons-material';
import {
  Grid, Typography, Box, IconButton, Popover, Paper, List, ListItemButton
} from '@mui/material';
import StatusBox from '../Table/StatusBox';
import { actions } from '../../redux/slices';
import { capitalizeFirstLetter, copyText, formateActionsButtons, formateDate } from '../../utils/helpers';
import PermissionWrapper from '../../services/PermissionWrapper';
import { noZeroMt } from '../../utils/config';

const CampaignsDetails = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const selectedCampaign = useSelector(({ table: { selectedItem } }) => selectedItem);
  const availableCountries = useSelector(({ session: { countries } }) => countries);
  useEffect(() => {
    return () => setAnchorEl(null);
  }, [selectedCampaign]);

  const actionsButtons = formateActionsButtons(selectedCampaign.status);

  const findCurCountry = () => {
    const res = availableCountries?.find(element => element.id === selectedCampaign.countryId).label || '';
    return capitalizeFirstLetter(res.toLowerCase())
  };

  const handleMoreOptions = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <Box sx={{ border: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderRadius: '10px' }} py={3} px={2}>
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Typography style={{ inlineSize: '80%', overflowWrap: 'break-word' }} variant='h3'>{selectedCampaign.name}</Typography>
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignSelf='flex-start'>
          <PermissionWrapper permId='campaignActions'>
            {!!actionsButtons.length && <Box><IconButton aria-describedby={Boolean(anchorEl) ? 'simple-popper' : undefined} onClick={handleMoreOptions}><MoreVert color='primary' /></IconButton></Box>}
          </PermissionWrapper>
          <Box><IconButton onClick={() => dispatch(actions.table.setSelectedItem(null))} ><Clear color='primary' /></IconButton></Box>
        </Box>
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        id='popperMoreOptions'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper elevation={2} px={2}>
          <List>
            {actionsButtons?.map((it) => (
              <ListItemButton
                key={it.label} onClick={() => {
                  if (it.modalConfig) {
                    dispatch(actions.ui.setModalOpen({ key: it.modalConfig, id: selectedCampaign.id }))
                  } else if (it.linkTo) {
                    navigate(`${selectedCampaign.id}`)
                  }
                }}>
                {it.icon}
                <Typography color='primary' variant='body2' sx={{ paddingX: '5px' }}>{it.label}</Typography>
              </ListItemButton>
            ))}
          </List>
        </Paper>
      </Popover>
      <Box display='flex' flexDirection='column'>
        <Box alignSelf='flex-start' pt={1} pb={4}>
          <StatusBox status={selectedCampaign.status} />
        </Box>
        <Box display='flex' flexDirection='row' alignItems='center' mb={3}>
          <Box ><Typography color='primary' variant='body2'>Campaign ID</Typography></Box>
          <Box px={1}><Typography variant='body2'>{selectedCampaign.id}</Typography></Box>
          <IconButton onClick={() => copyText(selectedCampaign.id)}  ><ContentCopyRounded sx={{ fontSize: '16px' }} color='primary' /></IconButton>

        </Box>
        <PermissionWrapper permId='customerNameRow'>
          <Box display='flex' flexDirection='row' alignItems='center' mb={4}>
            <Box ><Typography color='primary' variant='body2'>Customer ID</Typography></Box>
            <Box px={1}><Typography variant='body2'>{selectedCampaign.customerId}</Typography></Box>
            <IconButton onClick={() => copyText(selectedCampaign.customerId)}  ><ContentCopyRounded sx={{ fontSize: '16px' }} color='primary' /></IconButton >
          </Box>
        </PermissionWrapper>
        <Grid container sx={{ paddingBottom: '8px' }}>
          <Grid item md={6}><Typography color='primary' variant='body2'>Start date/time</Typography></Grid>
          <Grid item md={6}><Typography variant='body2'>{formateDate(selectedCampaign.started, selectedCampaign.timeZone)}</Typography></Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: '8px' }}>
          <Grid item md={6}><Typography color='primary' variant='body2'>End  date/time</Typography></Grid>
          <Grid item md={6}><Typography variant='body2'>{formateDate(selectedCampaign.finished, selectedCampaign.timeZone)}</Typography></Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: '8px' }}>
          <Grid item md={6}><Typography color='primary' variant='body2'>Time zone</Typography></Grid>
          <Grid item md={6}><Typography variant='body2'>{selectedCampaign.timeZone ? `${selectedCampaign.timeZone} (${dayjs().tz(selectedCampaign.timeZone).format('Z')})` : '-'}</Typography></Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: '8px' }}>
          <Grid item md={6}><Typography color='primary' variant='body2'>No of SMS expected</Typography></Grid>
          <Grid item md={6}><Typography variant='body2'>{selectedCampaign.mtExpected}</Typography></Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: '8px' }}>
          <Grid item md={6}><Typography color='primary' variant='body2'>No of SMS sent</Typography></Grid>
          <Grid item md={6}><Typography variant='body2'>{selectedCampaign.mtSent ? selectedCampaign.mtSent : noZeroMt.includes(selectedCampaign.status) ? '-' : 0}</Typography></Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: '8px' }}>
          <Grid item md={6}><Typography color='primary' variant='body2'>No of SMS delivered</Typography></Grid>
          <Grid item md={6}><Typography variant='body2'>{selectedCampaign.mtDelivered ? selectedCampaign.mtDelivered : noZeroMt.includes(selectedCampaign.status) ? '-' : 0}</Typography></Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: '8px' }}>
          <Grid item md={6}><Typography color='primary' variant='body2'>No of SMS non-delivered</Typography></Grid>
          <Grid item md={6}><Typography variant='body2'>{selectedCampaign.mtNonDelivered ? selectedCampaign.mtNonDelivered : noZeroMt.includes(selectedCampaign.status) ? '-' : 0}</Typography></Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: '8px' }}>
          <Grid item md={6}><Typography color='primary' variant='body2'>No of SMS failed</Typography></Grid>
          <Grid item md={6}><Typography variant='body2'>{selectedCampaign.mtFailed ? selectedCampaign.mtFailed : noZeroMt.includes(selectedCampaign.status) ? '-' : 0}</Typography></Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: '8px' }}>
          <Grid item md={6}><Typography color='primary' variant='body2'>Sender</Typography></Grid>
          <Grid item md={6}><Typography variant='body2'>{selectedCampaign.originator}</Typography></Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: '8px' }}>
          <Grid item md={6}><Typography color='primary' variant='body2'>Message text</Typography></Grid>
          <Grid item md={6}><Typography variant='body2'>{selectedCampaign.text}</Typography></Grid>
        </Grid>
      </Box>
    </Box >
  );
};

export default CampaignsDetails;
