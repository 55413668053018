import { useEffect, useState } from 'react'
import {
  RadioGroup, FormControlLabel, Radio, Button, Box, Checkbox, Typography, Link,
  Accordion, AccordionSummary, AccordionDetails, InputAdornment,
} from '@mui/material';
import { FileUpload, InfoOutlined, DeleteOutlineOutlined } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux/slices';
import filtersConfig from '../Filters/filtersConfig';
import { Input, Select } from '../Inputs';

import { email, length, onlyNumbers, numberMinMax } from '../../utils/validation';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { billingModel } from '../../utils/configs/selectOptions';

export const RunCampaign = ({ value, setValue }) => (
  < Box pb='24px'>
    <RadioGroup
      value={value}
      onChange={(e) => setValue(e.target.value)}
    >
      <FormControlLabel value='in5Min' control={<Radio />} label='Start campaign in 5 minutes' />
      <FormControlLabel value='now' control={<Radio />} label='Start campaign now' />
    </RadioGroup>
  </Box>
);

export const EditRecipient = ({ value, setValue, configKey, modalErrors, setModalErrors, }) => (
  <Box display='flex' flexDirection='column'  >
    <Input
      id='name'
      errors={modalErrors}
      setErrors={setModalErrors}
      tooltip='100 chars maximum.'
      validators={[(str) => length(str, 100)]}
      defaultValue={configKey?.additionalData?.currentName}
      value={value?.name} label='List name ' onChange={(e) => setValue({ ...value, name: e.target.value })} />
    <Button size="large" sx={{ marginBottom: '8px' }} component="label" color='primary' variant='outlined' startIcon={value?.file ? <CheckCircleOutlineIcon color='success' /> : <FileUpload />}>
      <input type="file" accept=".csv" hidden onChange={(e) => setValue({ ...value, file: e.target.files[0] })} />
      Replace MSISDN list
    </Button>
    {!!configKey?.additionalData?.linked && < Box py='8px'>
      <FormControlLabel control={<Checkbox value={value?.checked} color='secondary' onChange={(e) => setValue({ ...value, checked: e.target.checked })} />}
        label={<Typography color={value?.checked ? 'text.main' : 'primary'} variant='body2'> If you modify this list, the corresponding campaigns will be updated and recalculated.</Typography>} />
      <Typography color={value?.checked ? 'text.main' : 'primary'} sx={{ paddingX: '30px', fontSize: '14px' }}>Modifying a list will not affect campaigns in statuses Running and Paused.</Typography>
    </Box>}
  </Box >
)

export const CreateRecipient = ({ value, setValue, modalErrors, setModalErrors, }) => (
  <Box display='flex' flexDirection='column'  >
    <Input
      id='name'
      errors={modalErrors}
      setErrors={setModalErrors}
      tooltip='100 chars maximum.'
      validators={[(str) => length(str, 100)]}
      value={value?.name}
      label='List name '
      onChange={(e) => setValue({ ...value, name: e.target.value })} />
    <Button size="large" sx={{ marginBottom: '8px' }} component="label" color='primary' variant='outlined' startIcon={value?.file ? <CheckCircleOutlineIcon color='success' /> : <FileUpload />}>
      <input type="file" accept=".csv" hidden onChange={(e) => setValue({ ...value, file: e.target.files[0] })} />
      Upload file
    </Button>
  </Box >
);

export const DeleteRecipient = ({ configKey }) => configKey?.additionalData?.linked > 0 ? (
  <Box display='flex'>
    <InfoOutlined color='primary' fontSize='small' />
    <Box pl='8px'>
      <Typography color='primary' sx={{ fontSize: '14px', paddingBottom: '8px' }}>
        If you delete this list, the corresponding campaigns will go into draft status and will not be launched.
      </Typography>
      <Typography color='primary' sx={{ fontSize: '14px', paddingBottom: '16px' }}>
        Deleting a list will not affect campaigns in statuses Running and Paused.
      </Typography>
    </Box>
  </Box >
) : <></>

export const CreateCustomer = ({ value, setValue, configKey, modalErrors, setModalErrors, }) => (
  <Box display='flex' flexDirection='column'>
    <Input
      id='name'
      errors={modalErrors}
      setErrors={setModalErrors}
      tooltip='128 chars maximum.'
      validators={[(str) => length(str, 128)]}
      required
      defaultValue={configKey?.additionalData?.name}
      value={value?.name}
      label='Customer name'
      onChange={(e) => setValue({ ...value, name: e.target.value })} />
    <Input required type='number' defaultValue={configKey?.additionalData?.ssid} value={value?.ssid} label='SSID' onChange={(e) => setValue({ ...value, ssid: e.target.value })} />
    <Input value={value?.password} required={!configKey} label='PWD' onChange={(e) => setValue({ ...value, password: e.target.value })} />
    <Input
      required
      id='throughput'
      errors={modalErrors}
      setErrors={setModalErrors}
      validators={[(str) => numberMinMax({ str, max: 100, min: 1 })]}
      tooltip='Value must be between 1 and 100 inclusive.'
      inputProps={{ max: 100, min: 1 }}
      type='number'
      value={value?.throughput}
      label='Throughput (sms/sec)'
      onChange={(e) => {
        const throughput = e.target.value ? Number(e.target.value) : null;
        setValue({ ...value, throughput })
      }
      }
    />
    <Select
      optionLabelKey='name'
      required
      selectOptions={billingModel}
      defaultValue={configKey?.additionalData?.billingModel}
      value={value?.billingModel} label='Billing model'
      onChange={(newValue) => setValue({ ...value, billingModel: newValue })}
    />
    {value?.billingModel === 'PREPAID' && <Input type='number' required defaultValue={configKey?.additionalData?.credit} value={value?.credit} label='Credit value' onChange={(e) => setValue({ ...value, credit: e.target.value })} />}
  </Box >
);

export const CreateAdmin = ({ value, setValue, configKey, modalErrors, setModalErrors }) => (
  <Box display='flex' flexDirection='column'>
    <Input
      id='email'
      errors={modalErrors}
      setErrors={setModalErrors}
      validators={[(str) => email(str)]}
      defaultValue={configKey?.additionalData?.email}
      tooltip={'Email is not valid'}
      required
      value={value?.email}
      label='Admin email'
      onChange={(e) => setValue({ ...value, email: e.target.value })} />
  </Box >
);

export const CreateUser = ({ value, setValue, configKey, modalErrors, setModalErrors, isEdit }) => (
  <Box display='flex' flexDirection='column'>
    <Input
      idDisabled={isEdit}
      id='email'
      errors={modalErrors}
      setErrors={setModalErrors}
      validators={[(str) => email(str)]}
      defaultValue={configKey?.additionalData?.email}
      tooltip={'Email is not valid'}
      required
      value={value?.email}
      label='User email'
      onChange={(e) => setValue({ ...value, email: e.target.value })} />
    <Select
      noClareIcon={true}
      defaultValue={configKey?.additionalData?.role}
      optionLabelKey='name'
      required
      selectOptions={[{ id: 'USER', name: 'User' }, { id: 'MODERATOR', name: 'Moderator' }]}
      value={value?.role} label='Role'
      onChange={(newValue) => setValue({ ...value, role: newValue })}
    />
  </Box >
);

export const UpdatePWD = ({ value, setValue, configKey }) => (
  <Box display='flex' flexDirection='column'>
    <Input required value={value?.password} label='PWD' onChange={(e) => setValue({ ...value, password: e.target.value })} />
  </Box >
);
export const CreateAdminTopNotification = ({ configKey }) => configKey?.additionalData?.showNotification ? (
  <Box display='flex'>
    <CheckCircleOutlineIcon style={{ color: '#3BE85A' }} />
    <Box pl='8px'>
      <Typography variant='subtitle1' sx={{ marginBottom: '32px' }}>
        The customer has been created successfully.
      </Typography>
    </Box>
  </Box>
) : <></>;

export const Login = ({ value, setValue, configKey }) => (
  <Box display='flex' flexDirection='column'>
    <Input required value={value?.login} label='Email' onChange={(e) => setValue({ ...value, login: e.target.value })} />
    <Input
      showPassword={value?.showPassword}
      handleShowPassword={() => setValue({ ...value, showPassword: !value?.showPassword })}
      required type='password'
      value={value?.password} label='Password' onChange={(e) => setValue({ ...value, password: e.target.value })} />
  </Box >)

export const LoginBottom = () => {

  const dispatch = useDispatch();
  const handleForgotPassword = () => {
    dispatch(actions.ui.setModalOpen({
      key: 'forgotPassword',
    }))
  };
  const handleSignUp = () => {
    dispatch(actions.ui.setModalOpen({
      key: 'signUp',
    }))
  };

  return (
    <Box pb='16px'>
      <Typography
        variant='body2'
        color='secondary'
        sx={{ textAlign: 'center', paddingBottom: '8px', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={handleForgotPassword}
      >Forgot your password?
      </Typography>
      {/* <Typography
        variant='body2'
        color='secondary'
        sx={{ textAlign: 'center', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={handleSignUp}
      >I do not have an account
      </Typography> */}
    </Box>
  )
};


export const ForgotPassword = ({ value, setValue, configKey, modalErrors, setModalErrors }) => (
  <Box display='flex' flexDirection='column' sx={{ textAlign: 'center' }}>
    <Typography variant='h5' style={{ paddingBottom: '24px' }}>
      Enter your registered email below to receive password reset instruction
    </Typography>
    <Input
      errors={modalErrors}
      setErrors={setModalErrors}
      validators={[(str) => email(str)]}
      id='email'
      required
      value={value?.email}
      label='Email'
      tooltip={'Email is not valid'}
      onChange={(e) => setValue({ ...value, email: e.target.value })} />
  </Box >);

export const ChangePassword = () => (
  <Box display='flex' flexDirection='column' sx={{ textAlign: 'center' }}>
    <Typography variant='h5' style={{ paddingBottom: '24px' }}>
      Password reset instruction will been sent to your email.
    </Typography>
  </Box >);

export const SignUp = ({ value, setValue, modalErrors, setModalErrors }) => {
  return (
    <Box display='flex' flexDirection='column'>
      <Input
        id='name'
        required
        errors={modalErrors}
        setErrors={setModalErrors}
        value={value?.name}
        tooltip='128 chars maximum.'
        validators={[(str) => length(str, 128)]}
        label='Name'
        onChange={(e) => setValue({ ...value, name: e.target.value })}
      />
      <Input
        id='email'
        required
        value={value?.email}
        errors={modalErrors}
        setErrors={setModalErrors}
        tooltip='Email is not valid'
        validators={[(str) => email(str)]}
        label='Email' onChange={(e) => setValue({ ...value, email: e.target.value })} />
      <FormControlLabel control={<Checkbox color='secondary' value={value?.termsOfService} onChange={(e) => setValue({ ...value, termsOfService: e.target.checked })} />}
        label={<Typography color='primary' variant='body2'>I accept the Echovox <Link color='secondary' component="button" sx={{ fontSize: '16px' }} >Terms of Service</Link></Typography>} />
    </Box >
  );
};

export const SignUpBottom = () => {
  const dispatch = useDispatch();

  const handleLogIn = () => {
    dispatch(actions.ui.setModalOpen({
      key: 'login',
    }))
  };

  return (
    <Box pb='16px'>
      <Typography
        variant='body2'
        color='secondary'
        sx={{ textAlign: 'center', paddingBottom: '8px', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={handleLogIn}
      >I have already an account
      </Typography>
    </Box>
  )
};

export const SendTestSMS = ({ value, setValue, modalErrors, setModalErrors }) => (
  <Box display='flex' flexDirection='column'>
    <Box display='flex' pt={1} alignItems='start' mb='32px' >
      <InfoOutlined color='primary' fontSize='small' />
      <Box>
        <Typography sx={{ paddingLeft: '8px' }} color='primary' variant='body2'>
          Please, be aware that each attempt is charged.
        </Typography>
        <Typography sx={{ paddingLeft: '8px', paddingTop: '8px' }} color='primary' variant='body2'>
          The phone number must contain from 8 to 22 digits along with the country code.
        </Typography>
      </Box>
    </Box>
    <Input
      inputProps={{
        startAdornment: <InputAdornment position="start">+</InputAdornment>,
      }}
      id='phone'
      required
      errors={modalErrors}
      setErrors={setModalErrors}
      value={value?.phone}
      validators={[(str) => length(str, 22), (str) => length(str, 8, true), (str) => onlyNumbers(str)]}
      label='Phone number'
      onChange={(e) => setValue({ ...value, phone: e.target.value })} />
  </Box >
);

export const FilterWindow = ({ value, setValue, configKey, modalErrors, setModalErrors }) => {
  const [checkboxes, setCheckboxes] = useState(null);
  const tableFilter = useSelector(({ table: { filter } }) => filter);
  const handleSetClearCheckboxes = (curFilters) => {
    const checkboxesNames = {}
    filtersConfig[configKey?.additionalData?.filterScope].forEach((it) => { checkboxesNames[it.id] = curFilters ? curFilters.includes(it.id) : false })
    setCheckboxes(checkboxesNames);
  };
  useEffect(() => {
    const curFilters = tableFilter ? Object.keys(tableFilter) : []
    setValue({ ...tableFilter })
    handleSetClearCheckboxes(curFilters)
  }, [configKey])

  const handleSetCheckbox = (e) => {
    if (e.target.checked) {
      setValue({ ...value, [e.target.name]: null })
    } else {
      const newValues = { ...value };
      delete newValues[e.target.name];
      if (e.target.name === 'created') {
        delete newValues.condition;
      }
      setValue(newValues)
    }
    setCheckboxes({ ...checkboxes, [e.target.name]: e.target.checked })
  };

  return (
    checkboxes ? <Box display='flex' flexDirection='column' pb='16px'>
      {filtersConfig[configKey?.additionalData?.filterScope].map((item) => <Accordion
        expanded={checkboxes?.[item.id]}
        key={item.id}
      ><AccordionSummary>
          <FormControlLabel
            label={`By ${item.label}`}
            control={
              <Checkbox
                name={item.id}
                color='secondary'
                checked={checkboxes?.[item.id]}
                onChange={handleSetCheckbox} />}
          /></AccordionSummary>
        <AccordionDetails>
          {item?.filter ? item?.filter(value, setValue, modalErrors, setModalErrors) : ''}
        </AccordionDetails>
      </Accordion>)}
      <Box
        pt='16px'
        onClick={() => {
          setValue(null);
          handleSetClearCheckboxes();
        }}
        width='fit-content'
        display='flex'
        style={{ cursor: 'pointer' }}>
        <DeleteOutlineOutlined sx={{ fontSize: '22px' }} />
        <Typography style={{ textDecoration: 'underline', paddingLeft: '8px' }}>
          Clear filters
        </Typography>
      </Box>
    </Box> : <></>)

};

export const SendTestSMSSuccess = ({ configKey }) => (
  <Box mb='32px'>
    <Typography>
      The test campaign has been created successfully.
      <Typography>
        {`Campaign ID: ${configKey?.id}`}
      </Typography>
    </Typography>
    <Typography>
      You can check the status of the campaign.
    </Typography>
  </Box >
);

