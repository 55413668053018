import React, { useEffect, useState } from 'react';
import {
  Typography, Box, LinearProgress, Button, Tooltip,
  IconButton, TableContainer, Table, TableRow, TableCell,
  Grid, Switch, FormControlLabel, TableHead, TableBody,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import DetailPageLayout from '../../layouts/DetailPageLayout';
import { PersonAddAltRounded, EditOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { Input, Select } from '../../components/Inputs';
import { actions } from '../../redux/slices';
import { billingModel } from '../../utils/configs/selectOptions';
import thunkActions from '../../redux/actions';
import NotFoundScreen from '../NotFoundScreen';
import paths from '../../services/Routes/paths';
import { length, numberMinMax } from '../../utils/validation';
import { formateDate, capitalizeFirstLetter } from '../../utils/helpers';
import { allUsers } from '../../utils/configs/tableHeaders';
import StatusBox from '../../components/Table/StatusBox';

const CustomerDetailsScreen = () => {
  const { id } = useParams();
  const update = useSelector(({ ui: { update } }) => update);

  const dispatch = useDispatch();
  const [curCustomer, setCurCustomer] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [errors, setErrors] = useState([]);
  const disableSave = () => !curCustomer?.name || !curCustomer?.ssid || !hasChanges || errors.length > 0 || !curCustomer?.throughput
    || Number(curCustomer?.throughput) < 1 || Number(curCustomer?.throughput) > 100;

  const handleCreateUser = () => {
    dispatch(actions.ui.setModalOpen({
      additionalData: {
        customerId: curCustomer.id,
      },
      key: 'createAdmin',
    }))
  };
  const handleEditUser = (user) => {
    dispatch(actions.ui.setModalOpen({
      additionalData: {
        ...user,
      },
      key: 'editAdmin',
    }))
  };
  const handleUpdatePWD = () => {
    dispatch(actions.ui.setModalOpen({
      additionalData: {
        customerId: curCustomer.id,
      },
      key: 'updatePWD',
    }))
  };
  const handleUpdateCustomer = () => {
    dispatch(thunkActions.updateCustomer(curCustomer, customer))
  }

  const handleChange = () => curCustomer.disabled ?
    dispatch(actions.ui.setModalOpen({
      key: 'activateCustomer',
      id: id,
    })) :
    dispatch(actions.ui.setModalOpen({
      key: 'deactivateCustomer',
      id: id,
    }))

  useEffect(() => {
    setHasChanges(JSON.stringify(curCustomer) !== JSON.stringify(customer));

    return () => {
      setHasChanges(false);
    };
  }, [curCustomer]);

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const request = api.getCustomerById(id);
      request.then((res) => {
        if (res) {
          setCustomer(JSON.parse(JSON.stringify(res?.data)))
          setCurCustomer(res?.data);
        }
        setLoading(false);
      })
    }
    fetchData();
    return () => setCurCustomer(null)
  }, [id, update])

  if (loading) return <LinearProgress color='secondary' />
  if (!curCustomer && !loading) return <NotFoundScreen />;

  return (
    <DetailPageLayout
      sectionName={`Edit customer ${id}`}
      handleSave={handleUpdateCustomer}
      saveIsDisabled={disableSave()}
      parentPath={paths.customers}
    >
      <Input
        width='60%'
        required
        showTooltip={true}
        value={curCustomer?.name}
        label='Customer name'
        onChange={(e) => setCurCustomer({ ...curCustomer, name: e.target.value })}
        id='name'
        errors={errors}
        setErrors={setErrors}
        tooltip='128 chars maximum.'
        validators={[(str) => length(str, 128)]}
      />
      <Input
        width='60%'
        required
        type='number'
        value={curCustomer?.ssid} label='SSID'
        onChange={(e) => setCurCustomer({ ...curCustomer, ssid: e.target.value ? Number(e.target.value) : null })}
      />
      <Input
        id='throughput'
        showTooltip
        width='60%'
        required
        errors={errors}
        setErrors={setErrors}
        validators={[(str) => numberMinMax({ str, max: 100, min: 1 })]}
        tooltip='Value must be between 1 and 100 inclusive.'
        inputProps={{ max: 100, min: 1 }}
        type='number'
        value={curCustomer?.throughput} label='Throughput (sms/sec)'
        onChange={(e) => {
          const throughput = e.target.value ? Number(e.target.value) : null;
          setCurCustomer({ ...curCustomer, throughput })
        }
        }
      />
      <Select
        width='60%'
        idDisabled={true}
        optionLabelKey='name'
        required
        selectOptions={billingModel}
        value={curCustomer?.billingModel}
        label='Billing model'
        onChange={(newValue) => setCurCustomer({ ...curCustomer, billingModel: newValue })}
      />
      {curCustomer?.billingModel === 'PREPAID'
        && <Input
          width='60%'
          type='number'
          required
          value={curCustomer?.credit}
          label='Credit value'
          onChange={(e) => setCurCustomer({ ...curCustomer, credit: e.target.value })} />
      }
      <Button onClick={handleUpdatePWD} sx={{ padding: '5px', margin: '24px 0' }} variant='text' color='secondary' >Update PWD</Button>
      <Box p={1}>
        <FormControlLabel control={<Switch checked={!curCustomer.disabled} size="small" onChange={() => handleChange()} />} label={curCustomer.disabled ? 'Activate customer' : 'Deactivate customer'} />
      </Box>
      <Box py={3}><Typography variant='h3'>Users</Typography></Box>
      {curCustomer?.users?.length ?
        <TableContainer sx={{ border: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderRadius: '10px' }}>
          <Table aria-label="caption table">
            <TableHead>
              <TableRow>
                {allUsers.map((header) => (
                  <TableCell
                    key={header.key}>
                    <Box display='flex' justifyContent={header.key === 'status' ? 'center' : 'start'} alignItems='center'>
                      <Typography variant='subtitle2'>{header.label}</Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>

              {curCustomer?.users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell >
                    <Typography color='text.main' variant='body2'>{user.id}</Typography>
                  </TableCell>
                  <TableCell >
                    <Grid container alignItems='center'>
                      <Grid item md={6}><Typography color='text.main' variant='body2' >{user.email}</Typography></Grid>
                      <Grid item md={6}>
                        {user.role === 'ADMIN' && <Tooltip title='edit' onClick={() => handleEditUser(user)} placement="top" arrow><IconButton><EditOutlined /></IconButton></Tooltip>}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell >
                    <Typography color='text.main' variant='body2' >{capitalizeFirstLetter(user.role.toLowerCase())}</Typography>
                  </TableCell>
                  <TableCell >
                    <Typography color='text.main' variant='body2'>{formateDate(user.created)}</Typography>
                  </TableCell>
                  <TableCell >
                    <Typography color='text.main' variant='body2'>{formateDate(user.updated)}</Typography>
                  </TableCell>
                  <TableCell >
                    <StatusBox status={user.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer > :
        <Button onClick={handleCreateUser} sx={{ paddingLeft: '5px' }} variant="text" color='secondary' startIcon={<PersonAddAltRounded />}> Add user</Button>
      }

    </DetailPageLayout >
  )

}


export default CustomerDetailsScreen;