import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: { countries: null },
  reducers: {
    setCountries(store, action) {
      store.countries = action.payload;
    },

  }
});

export const sessionActions = sessionSlice.actions;

