import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, Tooltip, TextField, Button, MenuItem, IconButton, InputAdornment, Typography } from '@mui/material';
import { HelpCenter, InfoOutlined, VisibilityOff, Visibility } from '@mui/icons-material';

const Input = ({
  id,
  label,
  selectOptions,
  tooltip,
  info,
  required,
  button,
  optionKeys,
  multiline,
  width,
  value,
  onChange,
  hasErrors,
  defaultValue,
  idDisabled,
  type,
  showTooltip,
  showPassword,
  handleShowPassword,
  autoComplete,
  inputProps = {},
  errors = [],
  setErrors,
  validators,
}) => {

  const dispatch = useDispatch();
  const handleValidate = (e) => {
    const { name, value } = e.target
    const trimmedValue = value.trim()
    let isValid = true;
    if (validators) {
      validators.forEach((validator) => {
        const res = validator(trimmedValue);
        if (!res) {
          isValid = false
        }
      })
      if (!isValid && !errors?.includes(name)) {
        const newErrors = [...errors];
        newErrors.push(name)
        setErrors(newErrors)
      } else if (isValid && errors?.includes(name)) {
        const newErrors = [...errors].filter((it) => it !== name);
        setErrors(newErrors)
      }
    }
    onChange(e);
  }
  return (
    <>
      <Box pb={2} width={width} sx={{ position: 'relative' }}>
        <Box display='flex' alignItems='center'>
          <TextField
            autoComplete={autoComplete ? '' : 'off'}
            InputProps={
              type === 'password' ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Show password"
                      onClick={handleShowPassword}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                  </InputAdornment>
                ),
              } : {
                ...inputProps
              }}
            type={showPassword ? 'text' : type}
            disabled={idDisabled}
            defaultValue={defaultValue || ''}
            name={id}
            required={required}
            select={!!selectOptions}
            fullWidth
            label={label}
            variant="outlined"
            value={value}
            onChange={handleValidate}
            error={hasErrors || errors?.includes(id)}
            helperText={hasErrors || errors?.includes(id) ? tooltip : ''}
            multiline={multiline} >
            {selectOptions?.map((option) => (
              <MenuItem key={option[optionKeys.id]} value={option[optionKeys.id]}>
                {option[optionKeys.label]}
              </MenuItem>
            ))}
          </TextField>
          {button && <Box sx={{ minWidth: '105px' }} ml={1}><Button variant='outlined' color='text' onClick={() => dispatch(button.action())} >{button.name}</Button></Box>}
        </Box>

        {tooltip && showTooltip && <Tooltip sx={{ position: 'absolute', right: -24, top: 0 }} title={tooltip} placement="top" arrow>< HelpCenter fontSize='small' /></Tooltip>}

        {info && <Box display='flex' pt={1} alignItems='center' >
          <InfoOutlined color='primary' fontSize='small' />
          <Typography sx={{ paddingLeft: '8px' }} color='primary' variant='body2'>{info}</Typography>
        </Box>}
      </Box>
    </>
  )

}


export default Input;