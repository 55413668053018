import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux/slices';
import { Box, InputBase, IconButton } from '@mui/material';
import { SearchRounded, Clear } from '@mui/icons-material';

const Search = ({ handleSubmit, placeholder }) => {
  const dispatch = useDispatch();

  const tableSearch = useSelector(({ table: { search } }) => search);

  const [searchValue, setSearchValue] = useState(tableSearch?.value || '');

  const handleSubmitCampaign = (e) => {
    if (e.code === 'Enter' || e.type === 'click') {
      handleSubmit(searchValue.trim())
    }
  };

  const handleClear = () => {
    setSearchValue('')
    dispatch(actions.table.setSearch(null))
  }
  return (
    <Box
      display='flex' alignItems='center'
      sx={{ border: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderRadius: '10px', minWidth: '300px' }}
    >
      <Box><IconButton onClick={handleSubmitCampaign}><SearchRounded color='primary' /></IconButton></Box>
      <InputBase
        onKeyDown={handleSubmitCampaign}
        fullWidth
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={placeholder}
      />
      {searchValue && <Box><IconButton onClick={handleClear}><Clear color='primary' /></IconButton></Box>}

    </Box>
  )
}

export default Search;