import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Close, Event } from '@mui/icons-material';

const DatePickerInput = ({
  name,
  errors,
  setErrors,
  value,
  setValue,
  label,
  maxDate,
  minDate,
  fullWidth,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = (e) => {
    e.stopPropagation();
    setValue(null);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        slots={{
          openPickerIcon: () => value ? <Close onClick={handleClose} /> : <Event />
        }}
        slotProps={{
          textField: {
            sx: { input: { cursor: 'pointer' } },
            fullWidth: fullWidth,
            onMouseDown: (e) => {
              e.preventDefault();
            },
            onClick: (e) => {
              setOpen(true);
            },
            placeholder: null,
            readOnly: true
          },
        }}
        format='DD/MM/YYYY'
        onClose={() => setOpen(false)}
        open={open}
        onError={(error) => {
          if (!errors?.includes(name) && error) {
            setErrors([...errors, name])
          } else if (!error) {
            const newErrors = [...errors].filter((it) => it !== name)
            setErrors(newErrors)
          }
        }}
        label={label}
        maxDate={maxDate ? maxDate : null}
        minDate={minDate ? minDate : null}
        value={value ? dayjs(value) : null}
        onChange={setValue}
      />
    </LocalizationProvider>
  )
};

export default DatePickerInput;
