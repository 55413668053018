import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Grid, LinearProgress, Button } from '@mui/material';

import { PersonAddAltRounded } from '@mui/icons-material';
import DetailPageLayout from '../../layouts/DetailPageLayout';
import thunkActions from '../../redux/actions';
import Table from '../../components/Table';
import { usersAndModerators, } from '../../utils/configs/tableHeaders';
import PermissionWrapper from '../../services/PermissionWrapper';
import { permissionNaming } from '../../services/PermissionWrapper/permissions';
import { actions } from '../../redux/slices';

const UsersScreen = () => {
	const scope = useLocation().pathname.split('/')[1];
	const update = useSelector(({ ui: { update } }) => update);
	const userRole = useSelector(({ auth: { user } }) => user);

	const dispatch = useDispatch();
	const tableSortParam = useSelector(({ table: { sort } }) => sort);
	const tableFilter = useSelector(({ table: { filter } }) => filter);
	const tableSearch = useSelector(({ table: { search } }) => search);
	const tableIsLoading = useSelector(({ ui: { isLoading } }) => isLoading);
	const tableData = useSelector(({ table }) => table);
	const selectedCampaign = useSelector(({ table: { selectedItem } }) => selectedItem);
	const currentPage = useSelector(({ table: { page } }) => page);
	const currentRowsPerPage = useSelector(({ table: { rowsPerPage } }) => rowsPerPage);

	const handleCreateUser = () => {
		dispatch(actions.ui.setModalOpen({
			key: 'createUser',
		}))
	};
	const handleEditUser = (user) => {
		dispatch(actions.ui.setModalOpen({
			additionalData: {
				...user,
			},
			key: 'editUser',
		}))
	};
	useEffect(() => {
		dispatch(thunkActions.getUsers(currentPage + 1, currentRowsPerPage, tableFilter, tableSearch, tableSortParam, scope));
	}, [currentRowsPerPage, currentPage, tableFilter, tableSearch, tableSortParam, dispatch, scope, update]);

	return (
		<DetailPageLayout sectionName='Users' >
			{tableIsLoading ? <Box p={2}><LinearProgress color='secondary' /> </Box> : <>
				<Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' py={2}>
					<Box>
						<Button onClick={handleCreateUser} sx={{ paddingLeft: '5px' }} variant="text" color='secondary' startIcon={<PersonAddAltRounded />}> Add user</Button>
					</Box>
				</Box>
				<Grid container spacing={2} >
					<PermissionWrapper permId={permissionNaming.usersAndModeratorsTable} >
						<Table headers={usersAndModerators} tableData={tableData} />
					</PermissionWrapper>
				</Grid>
			</>}
		</DetailPageLayout>)
};

export default UsersScreen;