import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom'
import { Typography, Button, Box, Link } from '@mui/material';
import { passwordLettersNumbers } from '../../utils/validation';
import { useDispatch } from 'react-redux';
import { actions } from '../../redux/slices';

import { Input } from '../../components/Inputs';
import StartScreenLayout from '../../layouts/StartScreenLayout';
import { useEffect } from 'react';
import auth from '../../services/auth';
import api from '../../api';

const ResetPassword = () => {
  const dispatch = useDispatch()
  const [inputValues, setInputValues] = useState({ password: '', confirm: '' });
  const [showPassword, setShowPassword] = useState({ password: '', confirm: '' });
  const [error, setError] = useState([]);
  const [searchParams] = useSearchParams()

  useEffect(() => {
    let newErrors = [...error];
    if (!passwordLettersNumbers(inputValues.password) && !error.includes('password')) {
      newErrors.push('password');
    } else if (error.includes('password') && passwordLettersNumbers(inputValues.password)) {
      newErrors = error.filter((item) => item !== 'password')
    }
    if (inputValues.password !== inputValues.confirm && !error.includes('confirm')) {
      newErrors.push('confirm');
    } else if (error.includes('confirm') && inputValues.password === inputValues.confirm) {
      newErrors = error.filter((item) => item !== 'confirm')
    }
    setError(newErrors);
  }, [inputValues])

  const handleSavePassword = () => {
    const params = {
      token: searchParams.get('token'),
      id: searchParams.get('id'),
      password: inputValues.password,
    }
    const request = searchParams.get('type') === 'reset' ? api.resetPassword(params) : api.activateNewUser(params);
    request.then((res) => {
      if (res) {
        dispatch(actions.ui.setModalOpen({
          key: 'changePasswordSuccess',
        }))
      }
    })
  };

  return (
    <StartScreenLayout>
      <Box display='flex' flexDirection='column' >
        <Typography variant='h3'>Enter new password</Typography>
        <Box pt='24px'>
          <Input
            hasErrors={error.includes('password')}
            showTooltip={true}
            tooltip='Only latin letters and numbers'
            width='80%'
            showPassword={showPassword?.password}
            handleShowPassword={() => setShowPassword({ ...showPassword, password: !showPassword?.password })}
            type='password'
            onChange={(e) => setInputValues({ ...inputValues, password: e.target.value })}
            required={true}
            label='New password'
            value={inputValues.password}
          />
        </Box>
        <Box>
          <Input
            width='80%'
            showPassword={showPassword?.confirm}
            handleShowPassword={() => setShowPassword({ ...showPassword, confirm: !showPassword?.confirm })}
            type='password'
            onChange={(e) => setInputValues({ ...inputValues, confirm: e.target.value })}
            required={true}
            label='Confirm password'
            value={inputValues.confirm}
          />
        </Box>
        <Box pt={3} >
          <Button
            color='secondary'
            disabled={!inputValues.confirm || !inputValues.password || error.length > 0}
            variant="contained"
            style={{ minWidth: '167px' }}
            onClick={handleSavePassword}
          >Save new password
          </Button>
        </Box>
        {!auth.isSignedIn() &&
          <Box pt={3}>
            <Typography variant='body1' ><Link color='secondary' href="/">Back to Login</Link>
            </Typography>
          </Box>
        }
        <Box pt={1} display='flex'>
          <Typography style={{ paddingRight: '5px' }} variant='body1'>Contact us </Typography>
          <Typography variant='body1' color='secondary'>technical.support@echovox.com</Typography>
        </Box>
      </Box>

    </StartScreenLayout>

  )
}

export default ResetPassword;
