import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import DialogWindow from '../components/DialogWindow';

import './Layouts.css';

const StartScreenLayout = ({ children }) => {

  return (
    <Grid container columns={10} style={{ height: '100vh' }} alignItems='center'>
      <Grid md={2} xs={2} item >
      </Grid>
      <Grid md={3} xs={8} item >
        <Box className='logo' />
        {children}
      </Grid>
      <Grid md={5} xs={0} item className='mainImage' />
      <Grid md={2} xs={2} item >
      </Grid>
      <Grid item md={8} xs={8} alignSelf='end' >
        <Typography variant='body3' >Copyright &#169; {new Date().getFullYear()} Echovox. All Rights Reserved.
          This site is protected by reCAPTCHA and the <span>Google Privacy Policy</span> and <span>Terms of Service</span> apply.
        </Typography>
      </Grid>
      <DialogWindow />
    </Grid >
  )
}

export default StartScreenLayout;
