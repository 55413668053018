export const permissionNaming = {
  customerNameRow: 'customerNameRow',
  customersNavLink: 'customersNavLink',
  recipientsNavLink: 'recipientsNavLink',
  createCampaign: 'createCampaign',
  campaignActions: 'campaignActions',
  campaignNameOrIdSearch: 'campaignNameOrIdSearch',
  campaignAndCustomerNameOrIdSearch: 'campaignAndCustomerNameOrIdSearch',
  campaignTableWithCustomersData: 'campaignTableWithCustomersData',
  campaignTableData: 'campaignTableData',
  recipientsTable: 'recipientsTable',
  campaignEdit: 'campaignEdit',
  customersTable: 'customersTable',
  customerEdit: 'customerEdit',
  createRecipient: 'createRecipient',
  recipientTableActions: 'recipientTableActions',
  deactivateNotification: 'deactivateNotification',
  usersAndModeratorsTable: 'usersAndModeratorsTable',
  createUserOrModerator: 'createUserOrModerator',
};


const permissions = {
  superadmin: [
    permissionNaming.customerNameRow,
    permissionNaming.customersNavLink,
    permissionNaming.campaignAndCustomerNameOrIdSearch,
    permissionNaming.campaignTableWithCustomersData,
    permissionNaming.customersTable,
    permissionNaming.customerEdit,
  ],
  user: [
    permissionNaming.recipientsTable,
    permissionNaming.recipientsNavLink,
    permissionNaming.createCampaign,
    permissionNaming.campaignActions,
    permissionNaming.campaignNameOrIdSearch,
    permissionNaming.campaignTableData,
    permissionNaming.campaignEdit,
    permissionNaming.createRecipient,
    permissionNaming.recipientTableActions,
  ],
  moderator:[
    permissionNaming.recipientsTable,
    permissionNaming.recipientsNavLink,
    permissionNaming.createCampaign,
    permissionNaming.campaignActions,
    permissionNaming.campaignNameOrIdSearch,
    permissionNaming.campaignTableData,
    permissionNaming.campaignEdit,
    permissionNaming.createRecipient,
    permissionNaming.recipientTableActions,
  ], 
  admin: [
    permissionNaming.recipientsTable,
    permissionNaming.recipientsNavLink,
    permissionNaming.createCampaign,
    permissionNaming.campaignActions,
    permissionNaming.campaignNameOrIdSearch,
    permissionNaming.campaignTableData,
    permissionNaming.campaignEdit,
    permissionNaming.createRecipient,
    permissionNaming.recipientTableActions,
    permissionNaming.usersAndModeratorsTable,
    permissionNaming.createUserOrModerator,
  ],
  viewer: [
    permissionNaming.campaignTableData,
    // permissionNaming.recipientsTable,
    // permissionNaming.recipientsNavLink,
    permissionNaming.deactivateNotification,
  ],
};

export default permissions;
