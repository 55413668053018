import React from 'react';
import { Box, Typography } from '@mui/material';

const colors = {
  RUNNING: { color: '#4BCA51', background: '75,202,81,0.1' },
  PAUSED: { color: '#F1A129', background: '241,161,41,0.1' },
  SCHEDULED: { color: '#C74BCA', background: '199,75,202,0.1' },
  COMPLETED: { color: '#AC5C43', background: '172,92,67, 0.1' },
  CREATED: { color: '#257CFF', background: '37,124,255,0.1' },
  DRAFT: { color: '#E0BC00', background: '224,188,0,0.1' },
  CANCELED: { color: '#707070', background: '112,112,112,.1' },
  REJECTED: { color: '#FF3A3A', background: '255,58,58,0.1' },
  INTERRUPTED: { color: '#F17129', background: '241,113,41,0.1' },
  ACTIVE: { color: '#4BCA51', background: '75,202,81,0.1' },
  DEACTIVATED: { color: '#7D7C96', background: '125,124,150,0.1' },
  DELAYED: { color: '#4BCA51', background: '75,202,81,0.1' },
};


const StatusBox = ({ status }) => {
  const capitalizeFirstLetter = (string) => {
    if (string === 'delayed') {
      return 'Running'
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }


  return (
    <Box sx={{
      textAlign: 'center',
      border: 'solid',
      borderColor: `${colors[status]?.color}`,
      borderWidth: '1px',
      borderRadius: '30px',
      backgroundColor: `rgba(${colors[status]?.background})`,
      width: 'fit-content',
      padding: '1px 8px',
      margin: 'auto',
      fontSize: '14px',
      lineHeight: ' 1.43',
    }}>
      <Typography sx={{ color: `${colors[status]?.color}` }} variant='body3'>{status ? capitalizeFirstLetter(status?.toLowerCase()) : '-'}</Typography>
    </Box>

  );
};

export default StatusBox;
