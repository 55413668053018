import React from 'react';
import { Link } from 'react-router-dom'
import { Typography, Box } from '@mui/material';

import SearchOffOutlinedIcon from '@mui/icons-material/SearchOffOutlined';
const NotFoundScreen = () => (
  <Box>
    <Box display='flex' pt={6} pb={2}>
      <SearchOffOutlinedIcon style={{ color: '#BCBCBC' }} fontSize='large' />
      <Typography variant='h2' color='secondary.light'>Sorry, this page not found</Typography>
    </Box>
    <Link style={{ color: '#FD0D37' }} relative='path' to='..'>Go back</Link>
  </Box >
);

export default NotFoundScreen;
