import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Button, ButtonGroup, Box } from '@mui/material';
import { FilterListOutlined, Close } from '@mui/icons-material';
import { actions } from '../../redux/slices';
import thunkActions from '../../redux/actions';

const Filters = () => {
  const dispatch = useDispatch();
  const scope = useLocation().pathname.split('/')[1];
  const tableFilter = useSelector(({ table: { filter } }) => filter);
  const openFilters = () => {
    dispatch(actions.ui.setModalOpen({
      key: 'openFilters',
      additionalData: { filterScope: scope, }
    }))
  }
  const getCount = () => {
    if (!tableFilter) return '';
    const keys = Object.keys(tableFilter).filter((key) => key !== 'condition')
    return ` (${keys.length})`;
  };
  return (
    <>
      <Box minWidth='115px'>
        <ButtonGroup variant="outlined">
          <Button
            onClick={openFilters}
            startIcon={<FilterListOutlined />}>
            {`Filters${getCount()}`}
          </Button>
          {getCount() !== ''
            && <Button onClick={() => dispatch(thunkActions.setFilters())}>
              < Close />
            </Button>}
        </ButtonGroup>
      </Box>

    </>
  )
}

export default Filters;