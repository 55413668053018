import React from 'react';
import { Box, LinearProgress } from '@mui/material';

const LoadingScreen = () => (
  <Box width={400}>
    <LinearProgress color='secondary' />
  </Box>
);

export default LoadingScreen;
