const typography = {
  h1: {
    fontSize: '28px',
    fontWeight: 400,
  },
  h2: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
  h3: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  h4: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  h5: {
    fontSize: '20px',
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: '16px',
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
  },
  body3: {
    fontSize: '12px',
    fontWeight: 400,
  },
  allVariants: {
    fontSize: '14px',
    color: '#33324D'
  },
};

export default typography;