import {
  RunCampaign,
  EditRecipient,
  CreateRecipient,
  DeleteRecipient,
  CreateCustomer,
  CreateAdmin,
  CreateUser,
  CreateAdminTopNotification,
  Login,
  LoginBottom,
  ForgotPassword,
  SignUp,
  ChangePassword,
  SignUpBottom,
  UpdatePWD,
  SendTestSMS,
  FilterWindow,
  SendTestSMSSuccess,
} from './extraData';
import thunkActions from '../../redux/actions';
import { logIn, sendEmailForResetPassword, sendEmailForCreateUser } from '../../redux/actions/auth';

const modalConfig = {
  deleteCampaign: {
    text: 'Are you sure you want to delete the campaign?',
    agreeButtonText: 'Delete',
    dispatchAction: ({ id, additionalData, isUser }) => thunkActions.deleteCampaign(id, additionalData?.pushUrl, isUser)
  },
  pauseCampaign: {
    text: 'Are you sure you want to pause the campaign?',
    agreeButtonText: 'Pause',
    dispatchAction: ({ id, isUser }) => thunkActions.handleCampaignAction(id, 'pause', isUser)
  },
  runCampaign: {
    text: 'When would you like to start the campaign?',
    agreeButtonText: 'Ok, start',
    dispatchAction: ({ id, extraValue, additionalData, isUser }) => extraValue === 'now' ? thunkActions.handleCampaignAction(id, 'start', isUser) : thunkActions.handleCampaignAction(id, 'withDelay', isUser, additionalData.data),
    middleContent: (value, setValue) => <RunCampaign value={value} setValue={setValue} />,
  },
  resumeCampaign: {
    text: 'Are you sure you want to resume the campaign?',
    agreeButtonText: 'Resume',
    dispatchAction: ({ id, isUser }) => thunkActions.handleCampaignAction(id, 'resume', isUser)
  },
  cancelCampaign: {
    text: 'Are you sure you want to cancel the campaign?',
    agreeButtonText: 'Yes, cancel ',
    cancelButtonText: 'No',
    dispatchAction: ({ id, isUser }) => thunkActions.handleCampaignAction(id, 'cancel', isUser)
  },

  editRecipient: {
    text: 'Edit recipients list',
    agreeButtonText: 'Save',
    dispatchAction: ({ id, extraValue, isUser }) => thunkActions.editRecipient({ id: id, isUser, ...extraValue }),
    middleContent: (value, setValue, configKey, modalErrors, setModalErrors,) => <EditRecipient
      value={value} setValue={setValue} configKey={configKey} modalErrors={modalErrors} setModalErrors={setModalErrors} />,
    columnButtons: true,
    disabledSaveCondition: (extraValue, configKey) => (!extraValue?.checked && configKey?.additionalData?.linked) || (!extraValue?.name && !extraValue?.file)
  },
  createRecipient: {
    text: 'Create new recipients list',
    agreeButtonText: 'Create',
    dispatchAction: ({ extraValue }) => thunkActions.createRecipient(extraValue),
    middleContent: (value, setValue, configKey, modalErrors, setModalErrors) => <CreateRecipient modalErrors={modalErrors} setModalErrors={setModalErrors} value={value} setValue={setValue} />,
    columnButtons: true,
    disabledSaveCondition: (extraValue) => !extraValue?.name || !extraValue?.file
  },
  deleteRecipient: {
    topContent: (configKey) => <DeleteRecipient configKey={configKey} />,
    text: 'Are you sure you want to delete the list?',
    agreeButtonText: 'Yes',
    dispatchAction: ({ id, isUser }) => thunkActions.deleteRecipients(id, isUser)
  },
  createCustomer: {
    text: 'Create new customer',
    agreeButtonText: 'Create customer',
    columnButtons: true,
    middleContent: (value, setValue, configKey, modalErrors, setModalErrors) => <CreateCustomer
      value={value} setValue={setValue} modalErrors={modalErrors} setModalErrors={setModalErrors} />,
    dispatchAction: ({ extraValue }) => thunkActions.createCustomer(extraValue),
    disabledSaveCondition: (extraValue) => !extraValue?.password || !extraValue?.name || !extraValue?.throughput
      || Number(extraValue?.throughput) < 1 || Number(extraValue?.throughput) > 100 || !extraValue?.ssid || extraValue?.ssid === '0' || !extraValue?.billingModel,
  },
  activateCustomer: {
    text: 'Are you sure, you want to activate the customer?',
    agreeButtonText: 'Activate',
    dispatchAction: ({ id }) => thunkActions.activateOrDeactivateCustomer(id)
  },
  deactivateCustomer: {
    text: 'Are you sure, you want to deactivate the customer?',
    agreeButtonText: 'Deactivate',
    dispatchAction: ({ id }) => thunkActions.activateOrDeactivateCustomer(id)
  },
  activateUser: {
    text: 'Are you sure, you want to activate the user?',
    agreeButtonText: 'Activate',
    dispatchAction: ({ id }) => thunkActions.activateOrDeactivateUser(id)
  },
  deleteUser: {
    text: 'Account is not active yet, so it will be deleted. Are you sure, you want to delete this user?',
    agreeButtonText: 'Yes, delete',
    dispatchAction: ({ id }) => thunkActions.activateOrDeactivateUser(id)
  },
  deactivateUser: {
    text: 'Are you sure, you want to deactivate the user?',
    agreeButtonText: 'Deactivate',
    dispatchAction: ({ id }) => thunkActions.activateOrDeactivateUser(id)
  },

  createAdmin: {
    topContent: (configKey) => <CreateAdminTopNotification configKey={configKey} />,
    text: 'Create admin account',
    agreeButtonText: 'Create',
    middleContent: (value, setValue, configKey, modalErrors, setModalErrors) => <CreateAdmin
      configKey={configKey} value={value} setValue={setValue} modalErrors={modalErrors} setModalErrors={setModalErrors} />,
    columnButtons: true,
    disabledSaveCondition: (extraValue) => !extraValue?.email || extraValue?.errors,
    dispatchAction: ({ extraValue, additionalData }) => thunkActions.createAdmin(extraValue, additionalData)
  },
  createUser: {
    text: 'Create user account',
    agreeButtonText: 'Create',
    middleContent: (value, setValue, configKey, modalErrors, setModalErrors) => <CreateUser
      configKey={configKey} value={value} setValue={setValue} modalErrors={modalErrors} setModalErrors={setModalErrors} />,
    columnButtons: true,
    disabledSaveCondition: (extraValue) => !extraValue?.email || extraValue?.errors || !extraValue.role,
    dispatchAction: ({ extraValue, additionalData }) => thunkActions.createUser(extraValue, additionalData)
  },
  editAdmin: {
    text: 'Edit admin account',
    agreeButtonText: 'Update',
    columnButtons: true,
    middleContent: (value, setValue, configKey, modalErrors, setModalErrors) => <CreateAdmin
      configKey={configKey} value={value} setValue={setValue} modalErrors={modalErrors} setModalErrors={setModalErrors} />,
    dispatchAction: ({ extraValue, additionalData }) => thunkActions.editAdmin(extraValue, additionalData)
  },
  editUser: {
    text: 'Edit user account',
    agreeButtonText: 'Update',
    columnButtons: true,
    middleContent: (value, setValue, configKey, modalErrors, setModalErrors) => <CreateUser isEdit={true}
      configKey={configKey} value={value} setValue={setValue} modalErrors={modalErrors} setModalErrors={setModalErrors} />,
    dispatchAction: ({ extraValue, additionalData }) => thunkActions.editUser(extraValue, additionalData)
  },

  login: {
    text: 'Login into your account to continue',
    agreeButtonText: 'Enter',
    middleContent: (value, setValue, configKey) => <Login configKey={configKey} value={value} setValue={setValue} />,
    columnButtons: true,
    bottomContent: () => <LoginBottom />,
    dispatchAction: ({ extraValue, }) => logIn({ login: extraValue.login, password: extraValue.password })
  },
  forgotPassword: {
    text: 'Forgot password?',
    agreeButtonText: 'Send',
    dispatchAction: ({ extraValue, }) => sendEmailForResetPassword(extraValue.email),
    disabledSaveCondition: (extraValue) => !extraValue?.email || extraValue?.errors,
    middleContent: (value, setValue, configKey, modalErrors, setModalErrors) => <ForgotPassword value={value} setValue={setValue} modalErrors={modalErrors} setModalErrors={setModalErrors} />,
    columnButtons: true,
  },
  changePassword: {
    text: 'Do you want to change the password?',
    agreeButtonText: 'Reset',
    dispatchAction: ({ additionalData }) => sendEmailForResetPassword(additionalData.email),
    middleContent: () => <ChangePassword />,
  },
  changePasswordSuccess: {
    closeAction: (navigate) => navigate('/'),
    agreeButtonText: 'Reset',
    notification: 'The password has been set successfully.',
    noButtons: true,
  },
  createAccountSuccess: {
    notification: 'The account has been created successfully. Please check your email and follow the instructions.',
    noButtons: true,
  },
  signUp: {
    text: 'Create an account',
    agreeButtonText: 'Create an account',
    disabledSaveCondition: (extraValue) => !extraValue?.email || !extraValue?.termsOfService || extraValue?.errors || !extraValue?.name,
    middleContent: (value, setValue, configKey, modalErrors, setModalErrors) => < SignUp value={value} setValue={setValue} modalErrors={modalErrors} setModalErrors={setModalErrors} />,
    bottomContent: () => <SignUpBottom />,
    dispatchAction: ({ extraValue }) => sendEmailForCreateUser(extraValue),
    columnButtons: true,
  },
  updatePWD: {
    text: 'Update PWD',
    agreeButtonText: 'Update PWD',
    middleContent: (value, setValue, configKey) => <UpdatePWD configKey={configKey} value={value} setValue={setValue} />,
    dispatchAction: ({ extraValue, additionalData }) => thunkActions.updatePWD(extraValue, additionalData),
    columnButtons: true,
    disabledSaveCondition: (extraValue) => !extraValue?.password,
  },
  sendTestSMS: {
    text: 'Test campaign',
    agreeButtonText: 'Test',
    middleContent: (value, setValue, configKey, modalErrors, setModalErrors) => <SendTestSMS modalErrors={modalErrors} setModalErrors={setModalErrors} configKey={configKey} value={value} setValue={setValue} />,
    dispatchAction: ({ extraValue, additionalData }) => thunkActions.sendTestSMS(extraValue.phone, additionalData),
    columnButtons: true,
    disabledSaveCondition: (extraValue) => !extraValue?.phone,
  },
  sendTestSMSSuccess: {
    text: 'Test campaign',
    agreeButtonText: 'Check status',
    middleContent: (value, setValue, configKey) => <SendTestSMSSuccess configKey={configKey} value={value} setValue={setValue} />,
    columnButtons: true,
    dispatchAction: ({ id }) => thunkActions.checkTestCampaign(id),
  },
  campaignSaveAsDraft: {
    text: 'Schedule will not be saved. Do you  want to continue ?',
    agreeButtonText: 'Yes',
    dispatchAction: ({ extraValue, additionalData }) => thunkActions.campaignSaveAsDraft(additionalData),
  },
  openFilters: {
    text: 'Filter campaigns',
    agreeButtonText: 'Apply',
    columnButtons: true,
    middleContent: (value, setValue, configKey, modalErrors, setModalErrors) => <FilterWindow
      value={value} setValue={setValue} configKey={configKey}
      modalErrors={modalErrors} setModalErrors={setModalErrors}
    />,
    dispatchAction: ({ extraValue }) => thunkActions.setFilters(extraValue),
    disabledSaveCondition: (extraValue) => extraValue && Object.values(extraValue).some((x) => !x),
  },
};

export default modalConfig;