
export const defaultCampaign = {
  name: '',
  originator: '',
  text: '',
  scheduleTime: null,
  stopTime: null,
  consumerListId: '',
  notificationEmail: '',
  timeZone: null,
}
export const requiredCampaignFields = ['name', 'originator', 'text', 'consumerListId'];

export const defCustomer = {
  name: '',
  ssid: 0,
  // password: '',
  credit: 0,
};
