import axios from 'axios';
import { toast } from 'react-toastify';
import auth from '../services/auth';

export const SERVER = process.env.REACT_APP_URL;
export const axiosInstance = axios.create({
  baseURL: SERVER,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      auth.logout();
    } else {
      toast.error(error.response.data?.error)
    }
  }
);
