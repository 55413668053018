import React from 'react';
import { useLocation } from 'react-router-dom';

import CampaignRow from './CampaignRow';
import RecipientRow from './RecipientRow';
import CustomerRow from './CustomerRow';
import UsersRow from './UsersRow';


const Rows = ({ data, columns }) => {
  const scope = useLocation().pathname.split('/')[1];

  if (scope === 'campaigns') return <CampaignRow data={data} columns={columns * 2} />
  if (scope === 'recipients') return <RecipientRow data={data} columns={columns * 2} />
  if (scope === 'users') return <UsersRow data={data} columns={columns * 2} />

  return <CustomerRow data={data} columns={columns * 2} />
}

export default Rows;
