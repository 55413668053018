import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { PlaylistAdd } from '@mui/icons-material';

import thunkActions from '../../redux/actions';
import Filters from '../../components/Filters';
import { Box, Grid, LinearProgress, Button } from '@mui/material';
import Table from '../../components/Table';
import CampaignsDetails from '../../components/CampaignsDetails';
import Search from '../../components/Search';
import { campaignHeaders, superAdminCampaignHeaders, campaignHeadersActive } from '../../utils/configs/tableHeaders';
import PermissionWrapper from '../../services/PermissionWrapper';
import { permissionNaming } from '../../services/PermissionWrapper/permissions';
import { actions } from '../../redux/slices';
import paths from '../../services/Routes/paths';

const CampaignScreen = () => {
  const update = useSelector(({ ui: { update } }) => update);
  const userRole = useSelector(({ auth: { user } }) => user)?.role;

  const dispatch = useDispatch();
  const scope = useLocation().pathname.split('/')[1];
  const tableSortParam = useSelector(({ table: { sort } }) => sort);
  const tableFilter = useSelector(({ table: { filter } }) => filter);
  const tableSearch = useSelector(({ table: { search } }) => search);
  const tableIsLoading = useSelector(({ ui: { isLoading } }) => isLoading);
  const tableData = useSelector(({ table }) => table);
  const selectedCampaign = useSelector(({ table: { selectedItem } }) => selectedItem);
  const currentPage = useSelector(({ table: { page } }) => page);
  const currentRowsPerPage = useSelector(({ table: { rowsPerPage } }) => rowsPerPage);
  useEffect(() => {
    dispatch(thunkActions.getCampaigns(currentPage + 1, currentRowsPerPage, tableFilter, tableSearch, tableSortParam, scope, userRole === 'USER'));
  }, [currentRowsPerPage, currentPage, tableFilter, tableSearch, tableSortParam, dispatch, scope, update]);

  return (
    tableIsLoading ? <Box p={2}><LinearProgress color='secondary' /> </Box> : <>
      <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' py={2}>
        <Box display='flex' alignItems='center' >
          <Box pr={2}><Filters /></Box>
          <PermissionWrapper permId={permissionNaming.campaignNameOrIdSearch} >
            <Search
              handleSubmit={(searchValue) => dispatch(actions.table.setSearch({
                keys: ["name", 'id'],
                value: searchValue
              }))}
              placeholder="Search campaign name or ID"
            />
          </PermissionWrapper>
          <PermissionWrapper permId={permissionNaming.campaignAndCustomerNameOrIdSearch} >
            <Search
              handleSubmit={(searchValue) => dispatch(actions.table.setSearch({
                keys: ["name", 'id', 'customer_name', 'customer_id'],
                value: searchValue
              }))}
              placeholder="Search campaign/customer name or ID"
            />
          </PermissionWrapper>
        </Box>
        <PermissionWrapper permId='createCampaign'>
          <Box>
            <Button variant="contained" color='secondary' component={Link} to={`${paths.campaigns}/create`} startIcon={<PlaylistAdd />}>Create new campaign</Button>
          </Box>
        </PermissionWrapper>
      </Box>
      <Grid container spacing={2} >
        <PermissionWrapper permId={permissionNaming.campaignTableData} >
          <Table headers={userRole === 'VIEWER' ? campaignHeaders : campaignHeadersActive} selectable tableData={tableData} />
        </PermissionWrapper>
        <PermissionWrapper permId={permissionNaming.campaignTableWithCustomersData} >
          <Table headers={superAdminCampaignHeaders} selectable tableData={tableData} />
        </PermissionWrapper>
        {selectedCampaign
          && <Grid item md={3}>
            <CampaignsDetails />
          </Grid>
        }
      </Grid>
    </>
  )
};

export default CampaignScreen;