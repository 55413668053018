import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PersonAddAltRounded } from '@mui/icons-material';
import thunkActions from '../../redux/actions';
import { Box, Button, LinearProgress } from '@mui/material';
import Table from '../../components/Table';
import DialogWindow from '../../components/DialogWindow';
import { recipientsHeaders, recipientsHeadersActive } from '../../utils/configs/tableHeaders';
import { useLocation } from 'react-router-dom';
import { actions } from '../../redux/slices';
import PermissionWrapper from '../../services/PermissionWrapper';

const RecipientsScreen = () => {
  const dispatch = useDispatch();
  const scope = useLocation().pathname.split('/')[1];
  const userRole = useSelector(({ auth: { user } }) => user)?.role;

  const tableIsLoading = useSelector(({ ui: { isLoading } }) => isLoading);
  const tableData = useSelector(({ table }) => table);
  const tableScope = useSelector(({ table: { scope } }) => scope);

  const currentPage = useSelector(({ table: { page } }) => page);
  const currentRowsPerPage = useSelector(({ table: { rowsPerPage } }) => rowsPerPage);
  const update = useSelector(({ ui: { update } }) => update);

  useEffect(() => {

    dispatch(thunkActions.getRecipients(currentPage + 1, currentRowsPerPage, tableScope, userRole === 'USER'));

  }, [currentRowsPerPage, currentPage, dispatch, update, scope]);

  return (
    tableIsLoading ? <Box p={2}><LinearProgress color='secondary' /> </Box> : <>
      <Box display='flex' flexDirection='column' alignItems='center' py={2}>
        <PermissionWrapper permId='createRecipient' >
          <Box alignSelf='end'>
            <Button
              color='secondary'
              onClick={(e) => dispatch(actions.ui.setModalOpen({ key: 'createRecipient' }))}
              startIcon={<PersonAddAltRounded />} variant='contained' >Create new list</Button>
          </Box>
        </PermissionWrapper>
      </Box>
      <DialogWindow />
      <Table headers={userRole === 'VIEWER' ? recipientsHeaders : recipientsHeadersActive} tableData={tableData} />
    </>
  )
};

export default RecipientsScreen;