import { EditOutlined, PlayCircleOutlineOutlined, DeleteOutlineOutlined, PauseOutlined, HighlightOff } from '@mui/icons-material';
import { statuses } from './config';
import dayjs from 'dayjs'
export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const copyText = (text) => {
  navigator.clipboard.writeText(text);
};

export const formateActionsButtons = (status) => {
  const res = [];
  if (statuses.running.includes(status)) {
    res.push(status === 'RUNNING' ?
      { icon: <PauseOutlined color='primary' />, label: 'Pause', modalConfig: 'pauseCampaign' }
      :
      { icon: <PlayCircleOutlineOutlined color='primary' />, label: 'Start', modalConfig: 'runCampaign' }
    );
  }
  if (statuses.resume.includes(status)) {
    res.push({ icon: <PlayCircleOutlineOutlined color='primary' />, label: 'Resume', modalConfig: 'resumeCampaign' });
  }
  if (statuses.editable.includes(status)) {
    res.push({ icon: <EditOutlined color='primary' />, label: 'Edit', linkTo: 'campaigns' });
  }
  if (statuses.canceled.includes(status) || statuses.deletable.includes(status)) {
    res.push(statuses.canceled.includes(status) ?
      { icon: <HighlightOff color='primary' />, label: 'Cancel', modalConfig: 'cancelCampaign' }
      :
      { icon: <DeleteOutlineOutlined color='primary' />, label: 'Delete', modalConfig: 'deleteCampaign' }
    );
  }
  return res;
};

export const getTimeZones = () => {
  const names = Intl.supportedValuesOf("timeZone")

  const options = names.map((zone) => {
    return zone.replaceAll('_', ' ');
  });
  return options;
};

export const formateDate = (date, timeZone) => {
  if (!date) return '-';

  if (!timeZone) {
    const zone = dayjs.tz.guess()

    return dayjs(date).tz(zone).format('DD.MM.YYYY HH:mm:ss')
  } else {
    return dayjs(date).tz(timeZone).format('DD.MM.YYYY HH:mm:ss')
  }
};

export const formateTime = (time, timeZone) => {

  if (!time) return '-';
  const utcTime = dayjs(time, "HH:mm:ss").utc(true)

  if (!timeZone) {
    const zone = dayjs.tz.guess();
    return dayjs(utcTime).tz(zone).format("HH:mm:ss")
  } else {
    return dayjs(utcTime).tz(timeZone).format("HH:mm:ss")
  }
};
export const stopTimeBiggerThenStart = (fromTime, toTime) => {

  const ft = dayjs(`01-01-01 ${fromTime}`);
  const tt = dayjs(`01-01-01 ${toTime}`);
  if (!dayjs(tt).isValid()) return true;

  const mins = tt.diff(ft, "minutes", true);
  return mins >= 1
};

export const getFromLocalStorage = (naming) => {
  return JSON.parse(localStorage.getItem(naming))
};

export const formatFilters = (data) => {
  const filterConditions = [];
  let condition = 'between';
  const filterObj = { ...data };
  if (data.condition) {
    condition = data.condition;
    delete filterObj.condition
  }
  Object.keys(filterObj).forEach((key) => {
    if (key === 'created') {
      const values = []
      if (data[key]?.from) {
        values.push({
          value: data[key]?.from,
          condition: "GE"
        })
      }
      if (data[key]?.to) {
        values.push({
          value: dayjs(data[key]?.to).add(24, 'hour').toJSON(),
          condition: "LE"
        })
      }
      if (condition === 'exact') {
        values.push({
          value: dayjs(data[key]?.from).add(24, 'hour').toJSON(),
          condition: "LE"
        })
      }
      filterConditions.push({
        key: key,
        values: values,
      })
    } else {
      filterConditions.push({
        key: key,
        values: data[key]
      })
    }
  })
  return filterConditions;
};