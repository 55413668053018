import React from 'react';
import { useSelector, } from 'react-redux';
import permissions from './permissions';


const PermissionWrapper = ({ permId, children }) => {
  const user = useSelector(({ auth: { user } }) => user);
  if (permissions[user?.role?.toLowerCase()]?.includes(permId)) {
    return children;
  } else {
    return <></>
  }
}


export default PermissionWrapper;
