import { actions } from '../slices';
import api from '../../api';
import { defCustomer } from '../../utils/configs/defaultObjects';
import { toast } from 'react-toastify';

export const activateOrDeactivateCustomer = (id) => async (dispatch) => {
  let res;
  try {
    dispatch(actions.ui.setModalLoading(true))
    res = await api.activateOrDeactivateCustomer(id)
  } catch {
    console.log('catch')
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalClose());
  } else {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
  }
};
export const activateOrDeactivateUser = (id) => async (dispatch) => {
  let res;
  try {
    dispatch(actions.ui.setModalLoading(true))
    res = await api.activateOrDeactivateUser(id)
  } catch {
    console.log('catch')
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalClose());
  } else {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
  }
};

export const updateCustomer = (newData, data) => async (dispatch) => {
  let res;
  try {
    res = await api.updateCustomerById({ ...data, ...newData })
  } catch {
    console.log('catch')
  }
  if (res) {
    dispatch(actions.ui.setUpdate())
  }
};

export const createCustomer = (data) => async (dispatch) => {
  let res;
  try {
    dispatch(actions.ui.setModalLoading(true))
    res = await api.createCustomer({ ...defCustomer, ...data })
  } catch {
    console.log('catch')
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalOpen({
      key: 'createAdmin',
      additionalData: {
        ...res.data,
        customerId: res.data.id,
        showNotification: true,
      },
    }))
  } else {
    dispatch(actions.ui.setModalLoading(false))
  }
};

export const createAdmin = (newData, data) => async (dispatch) => {
  let res;
  dispatch(actions.ui.setModalLoading(true))

  try {
    res = await api.createAdmin({ ...newData, customerId: data.customerId, name: '' })
  } catch {
    console.log('catch')
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalClose());
  } else {
    dispatch(actions.ui.setModalLoading(false))
  }
};
export const createUser = (newData) => async (dispatch) => {
  let res;
  dispatch(actions.ui.setModalLoading(true))
  console.log()
  try {
    res = await api.createUser({ ...newData, name: '' })
  } catch {
    console.log('catch')
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalClose());
  } else {
    dispatch(actions.ui.setModalLoading(false))
  }
};
export const updatePWD = (newData, data) => async (dispatch) => {
  let res;
  dispatch(actions.ui.setModalLoading(true))
  try {
    res = await api.updateCustomerPWD(data.customerId, newData)
  } catch {
    console.log('catch')
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
    toast.success('The PWD has been updated successfully.')

  } else {
    dispatch(actions.ui.setModalLoading(false))
  }
};
export const editAdmin = (newData, data) => async (dispatch) => {
  let res;
  dispatch(actions.ui.setModalLoading(true))
  try {
    res = await api.updateAdminByID({ ...data, ...newData })
  } catch {
    console.log('catch')
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalClose());
  } else {
    dispatch(actions.ui.setModalLoading(false))
  }
};

export const editUser = (newData, data) => async (dispatch) => {
  let res;
  dispatch(actions.ui.setModalLoading(true))
  try {
    res = await api.updateUserByID({ ...data, ...newData })
  } catch {
    console.log('catch')
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalClose());
  } else {
    dispatch(actions.ui.setModalLoading(false))
  }
};
