
export const statuses = {
  deletable: ['CREATED', 'SCHEDULED', 'DRAFT'],
  canceled: ['RUNNING', 'PAUSED'],
  editable: ['CREATED', 'DRAFT', 'SCHEDULED',],
  running: ['RUNNING', 'CREATED',],
  resume: ['PAUSED',],
};

export const noZeroMt = ['SCHEDULED', 'CREATED', 'DRAFT']
