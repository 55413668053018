import { createSlice } from '@reduxjs/toolkit';


export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    authLoading: false,
    errors: null,
    isLoading: true,
    modalConfigKey: null,
    modalOpen: false,
    pushUrl: null,
    modalLoading: false,
    update: false,
  },
  reducers: {
    setErrors(store, action) {
      store.errors = action.errors;
    },
    setLoadingTrue(store) {
      store.authLoading = true;
    },
    setLoadingFalse(store) {
      store.authLoading = false;
    },
    setLoading(store, action) {
      store.isLoading = action.payload;
    },
    setModalOpen(store, action) {
      store.modalConfigKey = {
        id: action.payload.id,
        key: action.payload.key,
        additionalData: { ...action.payload.additionalData },
      };
      store.modalOpen = true;
    },
    setModalClose(store) {
      store.modalOpen = false;
      store.modalConfigKey = null;
    },
    setPushUrl(store, action) {
      store.pushUrl = action.payload ? action.payload : null;
    },
    setModalLoading(store, action) {
      store.modalLoading = action.payload;
    },
    setUpdate(store) {
      store.update = !store.update;
    },
  }
});

export const uiActions = uiSlice.actions;

