import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: 0,
  rowsPerPage: 10,
  items: null,
  selectedItem: null,
  sort: null,
  filter: null,
  search: null,
  total: 0,
  scope: '',
  update: false,
};
export const tableSlice = createSlice({
  name: 'table',
  initialState: initialState,
  reducers: {
    setPage(store, action) {
      store.page = action.payload;
      store.selectedItem = null;
    },
    setRowsPerPage(store, action) {
      store.rowsPerPage = action.payload;
      store.selectedItem = null;
      store.page = 0;
    },
    setTableItems(store, action) {
      if (store.scope && store.scope !== action.payload.scope) {
        store.page = 0
        store.selectedItem = null
        store.sort = null
        store.filter = null
        store.search = null
      }
      store.items = action.payload.items;
      store.total = action.payload.total;
      store.scope = action.payload.scope;
    },
    setSelectedItem(store, action) {
      store.selectedItem = action.payload;
    },
    setFilters(store, action) {
      store.filter = action.payload;
      store.selectedItem = null;
      store.page = 0;
    },
    setSort(store, action) {
      store.sort = action.payload;
      store.selectedItem = null;
      store.page = 0;
    },
    setSearch(store, action) {
      store.search = { ...action.payload };
      store.selectedItem = null;
      store.page = 0;
    },
    setUpdate(store,) {
      store.update = !store.update;
    },
    setSavedData(store, action) {
      store.search = action.payload; 
    },
    reset: () => initialState
  }
});

export const tableActions = tableSlice.actions;