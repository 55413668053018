import { actions } from '../slices';
import auth from '../../services/auth';
import api from '../../api';
import { toast } from 'react-toastify';
import { getFromLocalStorage } from '../../utils/helpers';

export const logIn = (login, password) => async (dispatch) => {
  dispatch(actions.ui.setLoadingTrue());
  dispatch(actions.ui.setModalLoading(true));


  const result = await auth.loginWithEmailAndPassword(login, password);
  if (result) {
    dispatch(actions.auth.setUser(result.user));
    dispatch(actions.ui.setModalLoading(false));

    dispatch(actions.ui.setModalClose());

  } else {
    dispatch(actions.ui.setModalLoading(false));
  }
}

export const initAuth = () => async (dispatch) => {
  const tableData = getFromLocalStorage('tableData')
  if(tableData){
    dispatch(actions.table.setSavedData(tableData));
  }
  const userData = await auth.handleAuthentication();
  if (userData?.message) {
    dispatch(actions.ui.setErrors(userData?.message));
    dispatch(actions.ui.setLoadingFalse());

  } else {
    dispatch(actions.auth.setUser(userData));
    dispatch(actions.ui.setLoadingFalse());
  }

};

export const logOut = () => async (dispatch) => {
  auth.logout();
};

export const sendEmailForResetPassword = (email) => async (dispatch) => {
  dispatch(actions.ui.setModalLoading(true))
  const result = await api.sendEmailForResetPassword(email);
  if (result) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
    toast.success('The email with the URL has been sent successfully. Please check your email and follow the instructions.')
  }
};
export const sendEmailForCreateUser = (data) => async (dispatch) => {
  dispatch(actions.ui.setModalLoading(true))
  const result = await api.sendEmailForCreateUser({ email: data.email, role: "ADMIN", name: data.name });
  if (result) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalOpen({
      key: 'createAccountSuccess',
    }))
  } else {
    dispatch(actions.ui.setModalLoading(false))

  }
};

export const updateCurrentUser = (data) => async (dispatch) => {
  dispatch(actions.ui.setLoadingTrue());
  const updateUser = await api.updateCurrentUser(data)
  if (updateUser) {
    dispatch(actions.auth.setUser(updateUser?.data));
    dispatch(actions.ui.setLoadingFalse());
  } else {
    dispatch(actions.ui.setLoadingFalse());
  }

};
