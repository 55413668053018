import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Typography, Box, Tooltip, IconButton, TableRow, TableCell } from '@mui/material';
import StatusBox from './StatusBox';
import { formateActionsButtons, formateDate } from '../../utils/helpers';
import PermissionWrapper from '../../services/PermissionWrapper';
import { actions } from '../../redux/slices';
import { noZeroMt } from '../../utils/config';
import dayjs from 'dayjs';

const CampaignRow = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTableItem = useSelector(({ table: { selectedItem } }) => selectedItem);

  useEffect(() => {
    if (selectedTableItem?.id) {
      const selected = data.filter((item) => item.id === selectedTableItem?.id)
      dispatch(actions.table.setSelectedItem(selected[0] || null))
    }
  }, [data]);

  return (
    data.map((item) =>
      <TableRow
        style={selectedTableItem?.id === item?.id ? { backgroundColor: '#ffffff', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' } : {}}
        hover
        onClick={() => dispatch(actions.table.setSelectedItem(item))}
        key={`${item.id}_row`}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <PermissionWrapper permId='customerNameRow'>
          <TableCell style={{ width: '20%' }}>
            <Typography variant='body2' color='text.main' >{item.customerName}</Typography>
            <Typography variant='body3' color='primary'>ID: {item.customerId}</Typography>
          </TableCell >
        </PermissionWrapper>
        <TableCell style={{ width: '20%' }}>
          <Typography variant='body2' color='text.main'>{item.name}</Typography>
          <Typography variant='body3' color='primary'>ID: {item.id}</Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <Typography variant='body2' color='text.main' >{formateDate(item.created)}</Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}> <Box display='flex' flexDirection='row'>
          <Box display='flex' flexDirection='column' pr={2}>
            <Box>
              <Typography variant='body2' color='text.main'>Expected:</Typography>
            </Box>
            <Box>
              <Typography variant='body2' color='text.main'>Sent:</Typography>
            </Box>
            <Box>
              <Typography variant='body2' color='text.main'>Delivered:</Typography>
            </Box>
            <Box>
              <Typography variant='body2' color='text.main'>Non-delivered:</Typography>
            </Box>
            <Box>
              <Typography variant='body2' color='text.main'>Failed:</Typography>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography variant='body2' color='text.main'>{item.consumerListId === 0 && item.mtExpected === 0 ? '-' : item.mtExpected}</Typography>
            </Box>
            <Box>
              <Typography variant='body2' color='text.main'>{item.mtSent ? item.mtSent : noZeroMt.includes(item.status) ? '-' : 0}</Typography>
            </Box>
            <Box>
              <Typography variant='body2' color='text.main'>{item.mtDelivered ? item.mtDelivered : noZeroMt.includes(item.status) ? '-' : 0}</Typography>
            </Box>
            <Box>
              <Typography variant='body2' color='text.main'>{item.mtNonDelivered ? item.mtNonDelivered : noZeroMt.includes(item.status) ? '-' : 0}</Typography>
            </Box>
            <Box>
              <Typography variant='body2' color='text.main'>{item.mtFailed ? item.mtFailed : noZeroMt.includes(item.status) ? '-' : 0}</Typography>
            </Box>
          </Box>
        </Box>
        </TableCell>
        <TableCell style={{ width: '20%' }}>
          <Box display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' pr={2}>
              <Box>
                <Typography variant='body2' color='text.main'>Start:</Typography>
              </Box>
              <Box>
                <Typography variant='body2' color='text.main'>End:</Typography>
              </Box>
              <Box>
                <Typography variant='body2' color='text.main'>Time zone:</Typography>
              </Box>
            </Box>

            <Box display='flex' flexDirection='column'>
              <Box>
                <Typography variant='body2' color='text.main'>{formateDate(item.started, item.timeZone)}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' color='text.main'>{formateDate(item.finished, item.timeZone)}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' color='text.main'>{item.timeZone ? `${item.timeZone} (${dayjs().tz(item.timeZone).format('Z')})` : '-'}</Typography>
              </Box>
            </Box>
          </Box>
        </TableCell>
        <TableCell style={{ width: '10%' }}>
          <StatusBox status={item.status} />
        </TableCell>
        <PermissionWrapper permId='campaignActions'>
          <TableCell>
            <Box display='flex' flexDirection='row' justifyContent='end'>
              {formateActionsButtons(item.status)?.map((it) => (
                <Box
                  mx={2}
                  key={it.label}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (it.modalConfig) {
                      dispatch(actions.ui.setModalOpen({ key: it.modalConfig, id: item.id, additionalData: { data: { ...item } } }))
                    } else if (it.linkTo) {
                      navigate(`${item.id}`)
                    }
                  }}
                >
                  <Tooltip title={it.label} placement="top" arrow><IconButton>{it.icon}</IconButton></Tooltip>
                </Box>
              ))}
            </Box>
          </TableCell>
        </PermissionWrapper>
      </TableRow>)
  )
};

export default CampaignRow;
