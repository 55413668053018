
export const latin_1_ISO_8859_1 = (str) => !/[^\u0000-\u00ff]/g.test(str)

export const length = (str, amount, moreThen) => moreThen && str ?
  str.length >= amount
  : str.length <= amount;

export const email = (value) => {
  return (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(value);
};

//Minimum eight characters, at least one letter and one number:
export const password = (value) => (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/).test(value);

//Only letters and numbers
export const passwordLettersNumbers = (value) => (/^[a-zA-Z0-9_.-]*$/).test(value);

export const onlyLatinLetters = (str) => (/^[a-zA-Z]*$/).test(str);

export const onlyNumbers = (str) => (/^[0-9]*$/).test(str);

export const numberMinMax = ({ str, min, max }) => Number(str) >= min && Number(str) <= max;