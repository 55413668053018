export const campaignHeaders = [
  { key: 'name', label: 'Campaign name', sortable: true },
  { key: 'created', label: 'Created', sortable: true },
  { key: 'mtExpected', label: 'No of SMS' },
  { key: 'started', label: 'Period', sortable: true },
  { key: 'status', label: 'Status', sortable: true },
];
export const superAdminCampaignHeaders = [
  { key: 'customer_name', label: 'Customer name', sortable: true },
  { key: 'name', label: 'Campaign name', sortable: true },
  { key: 'created', label: 'Created', sortable: true },
  { key: 'mtExpected', label: 'No of SMS', },
  { key: 'started', label: 'Period', sortable: true },
  { key: 'status', label: 'Status', sortable: true },
];
export const recipientsHeadersActive = [
  { key: 'listId', label: 'List ID' },
  { key: 'listName', label: 'List name' },
  { key: 'noOfMSISDN', label: 'No of MSISDN', },
  { key: 'created', label: 'Created' },
  { key: 'updated', label: 'Updated' },
  { key: 'actions', label: '' },
];
export const recipientsHeaders = [
  { key: 'listId', label: 'List ID' },
  { key: 'listName', label: 'List name' },
  { key: 'noOfMSISDN', label: 'No of MSISDN', },
  { key: 'created', label: 'Created' },
  { key: 'updated', label: 'Updated' },
];

export const customerHeaders = [
  { key: 'id', label: 'Customer id', sortable: true },
  { key: 'name', label: 'Customer name', sortable: true },
  { key: 'credit', label: 'Throughput' },
  // { key: 'billing_model', label: 'Billing model' },
  { key: 'created', label: 'Created', sortable: true },
  { key: 'updated', label: 'Updated', sortable: true },
  { key: 'disabled', label: 'Status', sortable: true },
  { key: 'actions', label: '' },
]
export const campaignHeadersActive = [
  { key: 'name', label: 'Campaign name', sortable: true },
  { key: 'created', label: 'Created', sortable: true },
  { key: 'mtExpected', label: 'No of SMS' },
  { key: 'started', label: 'Period', sortable: true },
  { key: 'status', label: 'Status', sortable: true },
  { key: 'actions', label: '' },
]
export const allUsers = [
  { key: 'id', label: 'User ID' },
  { key: 'email', label: 'User email' },
  { key: 'role', label: 'Role' },
  { key: 'created', label: 'Created' },
  { key: 'updated', label: 'Updated' },
  { key: 'status', label: 'Status' },
];

export const usersAndModerators = [
  { key: 'id', label: 'User ID', sortable: true },
  { key: 'email', label: 'User email', sortable: true },
  { key: 'role', label: 'Role', sortable: true },
  { key: 'created', label: 'Created', sortable: true },
  { key: 'updated', label: 'Updated', sortable: true },
  { key: 'status', label: 'Status', sortable: true },
  { key: 'actions', label: '' },
];

