import api from '../api';
import { axiosInstance } from '../api/axios';

class Auth {
  setAxiosInterceptors() {
    const token = this.getAccessToken();
    axiosInstance.interceptors.request.use(
      (config) => {
        const headers = { ...config.headers };
        if (token) {
          headers.Authorization = token;
        }
        return { ...config, headers };
      },
      (error) => Promise.reject(error),
    );

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.logout();
        }
        return Promise.reject(error);
      },
    );
  }

  handleAuthentication() {
    const accessToken = this.getAccessToken();
    if (!accessToken) {

      this.logout();
      return null;
    }
    return api.getCurrentUser()
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        this.logout();
        return error
      })
  }

  loginWithEmailAndPassword(login, password) {

    return api
      .login(login, password)
      .then((res) => {
        if (res?.data?.compactJws) {
          this.setSession(res?.data.compactJws);
          this.setAxiosInterceptors();
          return res?.data;
        }

        return res?.data.error;
      })
      .catch((error) => error)
  }


  logout() {
    this.setSession(null);
    window.location.reload();
  }

  setSession(accessToken) {
    if (accessToken) {
      return localStorage.setItem('accessToken', accessToken);

    }
    localStorage.removeItem('tableData');
    return localStorage.removeItem('accessToken');
  }

  getAccessToken() { return localStorage.getItem('accessToken') === 'null' ? null : localStorage.getItem('accessToken'); }


  isSignedIn() { return !!this.getAccessToken() }
}

const auth = new Auth();

export default auth;
