import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

import {
  TableContainer, Table as TableMui, TableRow, Typography,
  TableHead, TableCell, TableBody, Box, Grid, TablePagination, LinearProgress
} from '@mui/material';
import { actions } from '../../redux/slices';
import Rows from './Rows';



const Table = ({ selectable, headers }) => {
  const scope = useLocation().pathname.split('/')[1];
  const dispatch = useDispatch();
  const tableItems = useSelector(({ table: { items } }) => items);
  const selectedTableItem = useSelector(({ table: { selectedItem } }) => selectedItem);
  const tableData = useSelector(({ table }) => table);
  const tableSortParam = useSelector(({ table: { sort } }) => sort);

  const handleSetSort = (key) => {
    const direction = tableSortParam?.direction === 'DESC' ? 'ASC' : 'DESC';
    dispatch(actions.table.setSort({ key, direction }));
  };
  if (scope !== tableData.scope) return <LinearProgress />
  return (
    <Grid item md={selectedTableItem ? 9 : 12} width='100%'>
      <TableContainer sx={{ border: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderRadius: '10px' }}>
        <TableMui sx={{ minWidth: '1250px' }}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  key={header.key}>
                  <Box display='flex' justifyContent={(header.key === 'status' || header.key === 'disabled') ? 'center' : 'start'} alignItems='center' style={header.sortable ? { cursor: 'pointer' } : {}} onClick={header.sortable ? () => handleSetSort(header.key) : () => { }}>
                    <Typography variant='subtitle2'>{header.label}</Typography>
                    {tableSortParam?.key === header.key &&
                      <>
                        {tableSortParam?.direction === 'ASC' ? <ArrowDownward fontSize='small' color='primary' /> :
                          <ArrowUpward fontSize='small' color='primary' />}
                      </>
                    }
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!tableItems?.length ?
              <Rows data={tableItems} />
              : <TableRow>
                <TableCell>
                  <Typography style={{ color: '#33324D', fontSize: "14px" }}>No results</Typography>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </TableMui>
      </TableContainer>
      {
        !!tableItems?.length && <Box mb={4}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={tableData.total}
            page={tableData.page}
            onPageChange={(event, newPage) => dispatch(actions.table.setPage(newPage))}
            rowsPerPage={tableData.rowsPerPage}
            onRowsPerPageChange={(e) => dispatch(actions.table.setRowsPerPage(e.target.value))}
          />
        </Box>
      }
    </Grid >
  )
}

export default Table;