import React from 'react';
import { useDispatch } from 'react-redux';
import { EditOutlined } from '@mui/icons-material';
import { formateDate } from '../../utils/helpers';
import { useNavigate } from 'react-router-dom';

import {
  Typography, Box, Tooltip, IconButton, Switch, TableRow, TableCell,
} from '@mui/material';
import { actions } from '../../redux/slices';
import StatusBox from './StatusBox';
import paths from '../../services/Routes/paths';

const CustomerRow = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()


  const handleChange = (item) => item.disabled ?
    dispatch(actions.ui.setModalOpen({
      key: 'activateCustomer',
      id: item.id,
    })) :
    dispatch(actions.ui.setModalOpen({
      key: 'deactivateCustomer',
      id: item.id,
    }))

  return (
    data.map((item) =>
      <TableRow
        key={`${item.id}_row`}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell style={{ width: '15%' }}>
          <Typography color='text.main' variant='body2'>{item.id}</Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <Typography color='text.main' variant='body2'>{item.name}</Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <Typography color='text.main' variant='body2' >{`${item?.throughput} sms/sec`}</Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <Typography color='text.main' variant='body2'>{formateDate(item.created)}</Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <Typography color='text.main' variant='body2'>{formateDate(item.updated)}</Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <StatusBox status={item.disabled ? 'DEACTIVATED' : 'ACTIVE'} />
        </TableCell>
        <TableCell>
          <Box display='flex' flexDirection='row' justifyContent='space-around' alignItems='center'>
            <Tooltip title='Edit' placement="top" arrow>
              <IconButton
                onClick={() => navigate(`${paths.customers}/${item.id}`)}
              ><EditOutlined color='primary' /></IconButton>
            </Tooltip>
            <Tooltip title={item.disabled ? 'Activate' : 'Deactivate'} placement="top" arrow>
              <Box >
                <Switch checked={!item.disabled} size="small" onChange={() => handleChange(item)} />
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>)
  );
}
export default CustomerRow;
