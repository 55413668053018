import React, { useEffect, Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Grid, Typography, Box, Button,
  LinearProgress, Autocomplete, TextField, Tooltip, Paper, IconButton,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { statuses } from '../../utils/config';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import { checkIfDisabled, getDifference, getSendingTimeInfo } from './utils';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Link, useParams } from 'react-router-dom';
import { Input, Select } from '../../components/Inputs';
import dayjs from 'dayjs';
import config from './inputsConfig';
import api from '../../api';
import { defaultCampaign } from '../../utils/configs/defaultObjects';
import { useNavigate } from "react-router-dom";
import { DeleteOutlineOutlined, HelpCenter } from '@mui/icons-material';
import { actions } from '../../redux/slices';
import DetailPageLayout from '../../layouts/DetailPageLayout';
import NotFoundScreen from '../NotFoundScreen';
import paths from '../../services/Routes/paths';
import { formateDate, formateTime, stopTimeBiggerThenStart } from '../../utils/helpers';
import { Clear, MarkChatReadRounded, InfoOutlined } from '@mui/icons-material';

const CampaignDetailScreen = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const timeZoneNames = Intl.supportedValuesOf("timeZone");
  const [campaign, setCampaign] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [editSchedule, setEditSchedule] = useState();
  const [curCampaign, setCurCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sendingTime, setSendingTime] = useState('');
  const [startSendingTime, setStartSendingTime] = useState('');
  const [options, setOptions] = useState({ recipients: null });
  const [errors, setErrors] = useState([]);
  const updateOptions = useSelector(({ ui: { update } }) => update);
  const user = useSelector(({ auth: { user } }) => user);
  const [update, setUpdate] = useState(true);
  const [disabledSave, setDisabledSave] = useState(false);
  const pushUrl = useSelector(({ ui: { pushUrl } }) => pushUrl);

  useEffect(() => {
    if (pushUrl) {
      if (pushUrl === 'update') {
        setUpdate(!update)
        dispatch(actions.ui.setPushUrl(null));
      } else {
        navigate(pushUrl)
        dispatch(actions.ui.setPushUrl(null));
      }
    }
  }, [pushUrl, navigate])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const request = id !== 'create' ? api.getCampaignById(id, user.role === 'USER') : Promise.resolve({
        data: {},
      });
      request.then((res) => {
        let newSendingTime = ''
        if (res?.data.status) {
          newSendingTime = res.data.status === 'CREATED' ? 'doNotSend' :
            res.data.status === 'SCHEDULED' ? 'schedule' : '';
        }
        if (res) {
          setStartSendingTime(newSendingTime)
          setSendingTime(newSendingTime)
          const newCampaign = { ...defaultCampaign, ...res.data, notificationEmail: user.email, timeZone: res.data?.timeZone ? res.data?.timeZone : dayjs.tz.guess() };
          setCampaign(JSON.parse(JSON.stringify(newCampaign)))
          setCurCampaign(newCampaign);
          setEditSchedule(!newCampaign.scheduleTime)
        }
        setLoading(false);

      })
    }
    fetchData();
    return () => setCurCampaign(null)
  }, [id, update])

  useEffect(() => {
    const newHasChanges = (JSON.stringify(curCampaign) !== JSON.stringify(campaign)) || startSendingTime !== sendingTime;
    setHasChanges(newHasChanges);
    return () => {
      setHasChanges(false);
    };
  }, [curCampaign, sendingTime]);

  useEffect(() => {
    const fetchRecipients = async () => {
      api.getRecipients({ isUser: user.role === 'USER' }).then((recipient) => {
        const difference = getDifference(recipient?.data, options.recipients);
        if ((options.recipients?.length && difference.length) || (difference.length === 1 && options.recipients?.length === 0)) {
          setOptions({ ...options, recipients: [...recipient?.data] })
          setCurCampaign({ ...curCampaign, [config.recipients.id]: difference[0].id })
        } else {
          setOptions({ ...options, recipients: [...recipient?.data] })
        }
      })
    }
    fetchRecipients();
  }, [updateOptions]);

  const beforeSave = () => {
    let sendData = { ...curCampaign };
    if (sendingTime === 'delay' || sendingTime === 'doNotSend') {
      sendData.scheduleTime = null;
      sendData.stopTime = null;
      sendData.timeZone = dayjs.tz.guess();
    } else {
      const scheduleTime = dayjs(curCampaign.scheduleTime).tz(sendData.timeZone, true).toJSON();
      const stopTime = curCampaign.stopTime ? dayjs(curCampaign.stopTime).tz(sendData.timeZone, true).second(0).toJSON() : null;
      sendData.scheduleTime = scheduleTime;
      sendData.stopTime = stopTime ? `${dayjs(stopTime).utc().format("HH:mm:ss")}` : null;
    }
    return sendData;
  }
  const handleSave = () => {
    const isUser = user.role === 'USER';
    setDisabledSave(true);
    let sendData = beforeSave();
    const currentTime = dayjs.utc().format();
    const mins = dayjs(currentTime).diff(dayjs(sendData.scheduleTime), "minutes", true);
    if (mins > 0) {
      setDisabledSave(false);
      toast.error('The scheduled start time must be later than the current time of the corresponding time zone')
    } else {
      if (id !== 'create') {
        api.updateCampaign(sendData, isUser, sendingTime === 'delay')
          .then((res) => {
            if (res) {
              setUpdate(!update)
              setDisabledSave(false);
            }
          })
          .catch(() => setDisabledSave(false))
      } else {
        api.createCampaign(sendData, sendingTime === 'delay').then((res) => {
          navigate(`${paths.campaigns}/${res.data.id}`)
          setDisabledSave(false);
        })
          .catch(() => setDisabledSave(false));
      }
    }

  };
  useEffect(() => {
    if (curCampaign?.stopTime && curCampaign?.scheduleTime) {
      const stopTimeBigger = stopTimeBiggerThenStart(
        dayjs(curCampaign?.scheduleTime).format("HH:mm"),
        dayjs(curCampaign.stopTime).format("HH:mm"),
      )
      if (!stopTimeBigger && !errors.includes('stopTime')) {
        setErrors([...errors, 'stopTime'])
      } else if (errors.includes('stopTime') && stopTimeBigger) {
        const newErrors = [...errors].filter((it) => it !== 'stopTime');
        setErrors(newErrors)
      }
    } else if (errors.includes('stopTime')) {
      const newErrors = [...errors].filter((it) => it !== 'stopTime');
      setErrors(newErrors)
    }

  }, [curCampaign?.stopTime, curCampaign?.scheduleTime])

  const handleSendTestSMS = () => {
    const { originator, text, name } = curCampaign;
    dispatch(actions.ui.setModalOpen({
      key: 'sendTestSMS',
      additionalData: {
        originator,
        text,
        name,
        timeZone: dayjs.tz.guess(),
      }
    }))
  };
  const handleSaveAsDraft = () => {
    setDisabledSave(true);
    api.saveAsDraftCampaign({ ...curCampaign, scheduleTime: null, stopTime: null }).then((res) => {
      if (id !== 'create') {
        setUpdate(!update)
        setDisabledSave(false);
      } else {
        navigate(`${paths.campaigns}/${res.data.id}`)
        setDisabledSave(false);
      }
    })
  };
  const handleChangeSchedule = () => {
    setCurCampaign({
      ...curCampaign,
      scheduleTime: null,
      stopTime: null,
    })

    setEditSchedule(true);
  };

  if (loading) return <LinearProgress color='secondary' />
  if (!curCampaign && !loading) return <NotFoundScreen />;

  return (curCampaign && statuses.editable.includes(curCampaign.status)) || (id === 'create' && curCampaign) ? (
    <DetailPageLayout
      sectionName={id === 'create' ? 'Create campaign' : `Edit campaign ${id}`}
      noBorder={true}
    >
      <Grid container>
        <Grid item md={9} backgroundColor='background.light'>
          <Box
            height='100%'
            p={3}
            sx={{ border: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderRadius: '10px' }}>
            <Input
              {...config.name}
              width='60%'
              errors={errors}
              setErrors={setErrors}
              value={curCampaign[config.name.id]}
              onChange={(e) => setCurCampaign({ ...curCampaign, [config.name.id]: e.target.value })}
            />
            <Input
              {...config.originator}
              width='60%'
              errors={errors}
              setErrors={setErrors}
              value={curCampaign[config.originator.id]}
              onChange={(e) => setCurCampaign({ ...curCampaign, [config.originator.id]: e.target.value })}

            />
            <Input
              {...config.text}
              width='60%'
              errors={errors}
              setErrors={setErrors}
              value={curCampaign[config.text.id]}
              onChange={(e) => setCurCampaign({ ...curCampaign, [config.text.id]: e.target.value })}
            />
            <Select
              optionLabelKey='name'
              {...config.recipients}
              selectOptions={options.recipients || []}
              width='60%'
              value={curCampaign[config.recipients.id] || ''}
              onChange={(newValue) => setCurCampaign({ ...curCampaign, [config.recipients.id]: newValue })}
            />
            <Select
              optionLabelKey='name'
              {...config.sendingTime}
              width='60%'
              info={sendingTime ? <Typography color='primary' sx={{ fontSize: '12px' }}>{
                getSendingTimeInfo(sendingTime)
              }</Typography> : null}
              value={sendingTime}
              onChange={(newValue) => {
                setSendingTime(newValue)
              }}
            />
            {sendingTime === 'schedule' &&
              <>
                {editSchedule ? <>
                  <Box width='60%' pb={2}>
                    <Autocomplete
                      PaperComponent={({ children }) => (
                        <Paper style={{ marginBottom: 10 }}>{children}</Paper>
                      )}
                      options={timeZoneNames}
                      getOptionLabel={(option) => `${option} (${dayjs().tz(option).format('Z')})`}
                      fullWidth
                      value={curCampaign.timeZone}
                      onChange={(event, newValue) => {
                        setCurCampaign({
                          ...curCampaign, timeZone: newValue
                        })
                      }}
                      renderInput={(params) => <TextField {...params} required label="Time zone" />}
                    />
                  </Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box width='60%' display='flex' pb={2} sx={{ position: 'relative' }}>
                      <DateTimePicker
                        slotProps={{
                          textField: {
                            required: true,
                          },
                        }}
                        sx={{ marginRight: '16px', width: '50%' }}
                        format="DD/MM/YYYY HH:mm"
                        label="Start day/time"
                        value={curCampaign.scheduleTime || null}
                        onChange={(newValue) => {
                          setCurCampaign({ ...curCampaign, scheduleTime: newValue })
                        }}
                      />
                      <Box sx={{ position: 'relative', width: '50%' }}>
                        <TimePicker
                          sx={{ width: '100%' }}
                          slotProps={{
                            textField: {
                              helperText: errors.includes('stopTime') ? 'The stop time must be later than the start time' : '',
                            },
                          }}
                          minTime={errors.includes('stopTime') && curCampaign?.stopTime ? dayjs(curCampaign?.stopTime.add(2, 'hour')).startOf('hour') : null}
                          format="HH:mm"
                          label="Stop time"
                          value={curCampaign.stopTime || null}
                          onChange={(newValue) => {
                            setCurCampaign({ ...curCampaign, stopTime: newValue })
                          }}
                        />
                        {curCampaign?.stopTime &&
                          <IconButton sx={{ position: 'absolute', right: 0, top: 5, color: '#757575' }} onClick={() => setCurCampaign({ ...curCampaign, stopTime: null })}><Clear />
                          </IconButton >
                        }
                      </Box>
                      <Tooltip sx={{ position: 'absolute', right: -24, top: 0 }} title='The campaign will be stopped and resumed on the next day at a start time.' placement="top" arrow>
                        < HelpCenter fontSize='small' />
                      </Tooltip>

                    </Box>
                  </LocalizationProvider>
                </> :
                  <Box
                    p='14px'
                    mb={2}
                    width='60%'
                    sx={{ border: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderRadius: '10px' }}
                    display='flex'
                    justifyContent='space-between'
                  >
                    <Box>
                      <Box pb={2}>
                        <Typography variant='body3' color='primary'>Time zone</Typography>
                        <Typography>{`${curCampaign.timeZone} (${dayjs().tz(curCampaign.timeZone).format('Z')})`}</Typography>
                      </Box>
                      <Box display='flex'>
                        <Box>
                          <Typography variant='body3' color='primary'>Start date and time</Typography>
                          <Typography>{formateDate(curCampaign.scheduleTime, curCampaign.timeZone)}</Typography>
                        </Box>
                        <Box pl={6}>
                          <Typography variant='body3' color='primary'>Stop time</Typography>
                          <Typography>{formateTime(curCampaign.stopTime, curCampaign.timeZone)}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box alignSelf='center'>
                      <IconButton onClick={handleChangeSchedule}><DeleteOutlineOutlined style={{ cursor: 'pointer' }} color='primary' /></IconButton>
                    </Box>
                  </Box>
                }
              </>
            }
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box display='flex' flexDirection='column' sx={{ height: '90%' }}>
            <Box p={2}>
              <Box pb={4}>
                <Typography variant='h3'>Campaign details</Typography>
              </Box>
              <Box>
                <Typography color='primary' variant='subtitle1' >Total number of SMS:</Typography>
              </Box>
              <Box>
                <Typography color={curCampaign?.mtExpected ? 'text' : 'secondary.light'} variant='h2'>{`${curCampaign?.mtExpected || 0} SMS`}</Typography>
              </Box>
              <Box pt='32px'>
                <Button
                  color='text'
                  disabled={!curCampaign.text || !curCampaign.originator || !curCampaign.name}
                  startIcon={<MarkChatReadRounded />}
                  style={{ color: '#FFFFFF' }}
                  onClick={handleSendTestSMS}
                  variant='contained'>
                  Test campaign
                </Button>
              </Box>
              <Box display='flex' pt={1} alignItems='center' >
                <InfoOutlined color='primary' fontSize='small' />
                <Typography sx={{ paddingLeft: '8px' }} color='primary' variant='body2'>You can send test SMS. Each attempt is charged.</Typography>
              </Box>
            </Box>
            <Box sx={{ minHeight: '200px' }}></Box>
            <Box display='flex' justifyContent='center' flexDirection='column' p={2}>
              {(!curCampaign.status || curCampaign.status === 'DRAFT') &&
                <Box>
                  <Button
                    color='secondary'
                    disabled={errors.length > 0 || !hasChanges || disabledSave}
                    onClick={() => {
                      if (sendingTime === 'delay' || sendingTime === 'schedule') {
                        const additionalData = beforeSave();
                        dispatch(actions.ui.setModalOpen({
                          key: 'campaignSaveAsDraft',
                          id: id,
                          additionalData,
                        }))
                      } else {
                        handleSaveAsDraft()
                      }
                    }}
                    sx={{ width: '100%' }} variant={'outlined'} >Save as draft</Button>
                </Box>}
              <Box py={1}>
                <Button color='secondary'
                  disabled={checkIfDisabled(errors, sendingTime, curCampaign) || !hasChanges || disabledSave}
                  sx={{ width: '100%' }}
                  onClick={handleSave} variant='contained'>
                  {(id === 'create' || curCampaign?.status === 'DRAFT') ? 'Create campaign' : 'Save'}
                </Button>
              </Box>
              {curCampaign.id && <Box py={1}>
                <Box
                  onClick={() => dispatch(actions.ui.setModalOpen({ key: 'deleteCampaign', id: curCampaign.id, additionalData: { pushUrl: paths.campaigns } }))}
                  display='flex'
                  style={{ cursor: 'pointer' }}>
                  <DeleteOutlineOutlined sx={{ fontSize: '22px' }} />
                  <Typography style={{ textDecoration: 'underline', paddingLeft: '8px' }}>
                    Delete campaign
                  </Typography>
                </Box>
              </Box>}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </DetailPageLayout>
  ) : <NotFoundScreen />

}


export default CampaignDetailScreen;