import { actions } from '../slices';
import api from '../../api';
import dayjs from 'dayjs';

export const resetTableData = () => (dispatch) => {
  dispatch(actions.table.reset());

}
export const setFilters = (filter) => async (dispatch) => {
  if (filter) {
    const filters = { ...filter };

    if (filter.created) {
      const created = {};
      Object.keys(filters.created).forEach((key) => {
        created[key] = dayjs(filters.created[key]).utc().toJSON()
      })
      filters.created = created;
    }
    dispatch(actions.table.setFilters(filters));
  } else {
    dispatch(actions.table.setFilters(null));

  }

  dispatch(actions.ui.setModalClose());
};

export const getCampaigns = (page, size, filter, searchParam, sort, scope, isUser) => async (dispatch) => {
  dispatch(actions.ui.setLoading(true));

  const search = searchParam?.value ? searchParam : null;
  const params = { page, size, filter, search, sort, isUser }

  if (scope !== 'campaigns') {
    params.page = 1;
    params.search = null;
    params.sort = null;
    params.filter = null;
  }
  try {
    let result = await api.getCampaigns(params);
    dispatch(actions.ui.setPushUrl());

    if (!result.data.list.length && page > 1) {
      dispatch(actions.table.setPage(0))
    } else {
      dispatch(actions.table.setTableItems({ items: result.data.list, total: result.data.total, scope: 'campaigns' }))
    }
    dispatch(actions.ui.setLoading(false));

  }
  catch {
    console.log('catch')
  }
};


export const getRecipients = (page, size, scope, isUser) => async (dispatch) => {
  dispatch(actions.ui.setLoading(true));
  const params = { page, size, isUser };
  if (scope !== 'recipients') {
    params.page = 1;
    params.search = null;
    params.sort = null;
    params.filter = null;
  }
  try {
    let result = await api.getRecipients(params);
    if (!result.data.list.length && page > 1) {
      result = await api.getRecipients({ page: 1, size });
      dispatch(actions.table.setPage(0))
    }
    dispatch(actions.table.setTableItems({ items: result.data.list, total: result.data.total, scope: 'recipients' }))
    dispatch(actions.ui.setLoading(false));
  }
  catch {
    console.log('catch')
  }
};

export const getCustomers = (page, size, searchParam, sort, scope) => async (dispatch) => {
  dispatch(actions.ui.setLoading(true));
  const search = searchParam?.value ? searchParam : null;
  const params = { page, size, search, sort };

  if (scope !== 'customers') {
    params.page = 1;
    params.search = null;
    params.sort = null;
    params.filter = null;
  }
  try {
    let result = await api.getAllCustomers(params);
    if (!result.data.list.length && page > 1) {
      dispatch(actions.table.setPage(0))
    } else {
      dispatch(actions.table.setTableItems({
        items: result.data.list,
        total: result.data.total,
        scope: 'customers'
      }))
    }
    dispatch(actions.ui.setLoading(false));

  }
  catch {
    console.log('catch')
  }
};

export const getUsers = (page, size, filter, searchParam, sort, scope) => async (dispatch) => {
  dispatch(actions.ui.setLoading(true));
  const search = searchParam?.value ? searchParam : null;
  const params = { page, size, search, sort, filter };

  if (scope !== 'users') {
    params.page = 1;
    params.search = null;
    params.sort = null;
    params.filter = null;
  }
  try {
    let result = await api.getAllUsersForCurrentCustomer(params);
    if (!result.data.list.length && page > 1) {
      dispatch(actions.table.setPage(0))
    } else {
      dispatch(actions.table.setTableItems({
        items: result.data.list,
        total: result.data.total,
        scope: 'users'
      }))
    }
    dispatch(actions.ui.setLoading(false));

  }
  catch {
    console.log('catch')
  }
};

