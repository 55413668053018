import { InputWithChips, Select, DatePickerInput } from "../Inputs";
import { statuses } from '../../utils/configs/selectOptions';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { dateFilterCondition } from '../../utils/configs/selectOptions';

const filtersConfig = {
  campaigns: [
    {
      id: 'status',
      label: 'status',
      filter: (value, setValue) => (
        <InputWithChips
          optionLabelKey='name'
          selectOptions={statuses}
          onChange={(newValue) => setValue({ ...value, status: newValue.length ? newValue : null })}
          value={value?.status || []}
        />
      )
    },
    {
      id: 'created',
      label: 'created date',
      filter: (value, setValue, modalErrors, setModalErrors) => (
        <>
          <Select
            noClareIcon={true}
            selectOptions={dateFilterCondition}
            required
            defaultValue='between'
            value={value?.condition}
            onChange={(newValue) => {
              setValue({ ...value, condition: newValue, created: null })
            }}
            optionLabelKey='name'
          />
          <Box display='flex' whiteSpace='8px'>
            {value?.condition !== 'to'
              && <DatePickerInput
                fullWidth={value?.condition && value?.condition !== 'between'}
                maxDate={dayjs()}
                name='from'
                label={value?.condition && value?.condition !== 'between' ? 'Day' : 'From'}
                value={value?.created?.from}
                setValue={(newValue) => {
                  if (!newValue && !value?.created?.to) {
                    setValue({ ...value, created: null })
                  } else {
                    setValue({ ...value, created: { ...value.created, from: newValue } })
                  }
                }}
                errors={modalErrors}
                setErrors={setModalErrors}
              />}
            {(!value?.condition || value?.condition === 'between') && <Box mx={1}></Box>}
            {(!value?.condition || value?.condition === 'to' || value?.condition === 'between')
              && <DatePickerInput
                name='to'
                label={value?.condition === 'to' ? 'Day' : 'To'}
                value={value?.created?.to}
                setValue={(newValue) => {
                  if (!newValue && !value?.created?.from) {
                    setValue({ ...value, created: null })
                  } else {
                    setValue({ ...value, created: { ...value.created, to: newValue } })
                  }
                }}
                fullWidth={value?.condition === 'to'}
                maxDate={dayjs()}
                errors={modalErrors}
                setErrors={setModalErrors}
              />}
          </Box>
        </>
      )

    },
  ]
};

export default filtersConfig;