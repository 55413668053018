import React from 'react';
import {
  Typography, Box, Divider, Button, Breadcrumbs,
} from '@mui/material';

import { Clear, NavigateNext, Save } from '@mui/icons-material';
import { Link } from 'react-router-dom';


const DetailPageLayout = ({ children, sectionName, noBorder, handleSave, saveIsDisabled }) => {
  const breadcrumbs = () => [
    <Typography key='home' variant='body2'><Link style={{ textDecoration: 'none', color: ' #7D7C96' }} relative='path' to='..'>Home</Link></Typography>,

    <Typography key={sectionName} variant='body2' color='text.main'>
      {sectionName}
    </Typography>,
  ];

  return (
    <>
      <Box
        position="fixed"
        sx={{ top: '60px', left: 0, right: 0, height: '60px', zIndex: 2 }}
        backgroundColor='background.default'
      >
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ height: '60px' }}>
          <Box px={5} py='10px'>
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs()}
            </Breadcrumbs>
          </Box>
          <Box display='flex'>
            <Box pr={3}>
              <Button component={Link} to='..' relative='path' color='primary' startIcon={<Clear />} variant='outlined'>Cancel</Button>
            </Box>
            {handleSave &&
              <Box pr={3}>
                <Button color='secondary' disabled={saveIsDisabled} startIcon={<Save />} onClick={handleSave} variant='contained'>Save</Button>
              </Box>}
          </Box>
        </Box>
        <Divider />
      </Box>
      <Box mt='144px'>
        <Box
          backgroundColor={noBorder ? '' : 'background.light'}
          p={noBorder ? 0 : 3}
          sx={noBorder ? {} : { border: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px', borderRadius: '10px' }}>
          {children}
        </Box>

      </Box>
    </>
  )

}


export default DetailPageLayout;