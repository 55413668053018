import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ResetPassword from '../../screens/ResetPassword'
import StartScreen from '../../screens/StartScreen';
import paths from './paths';

const GuestRoutes = () => (
  <Routes>
    <Route path="/" element={<StartScreen />} />
    <Route path={paths.setpassword} element={<ResetPassword />} />
    <Route path="*" element={<Navigate to={'/'} replace />} />
  </Routes>
);



export default GuestRoutes;
