import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PersonAddRounded } from '@mui/icons-material';
import thunkActions from '../../redux/actions';
// import Filters from '../../components/Filters';
import { Box, Grid, LinearProgress, Button } from '@mui/material';
import Table from '../../components/Table';
import Search from '../../components/Search';
import { customerHeaders } from '../../utils/configs/tableHeaders';

import { actions } from '../../redux/slices';

const CustomerScreen = () => {
  const scope = useLocation().pathname.split('/')[1];
  const dispatch = useDispatch();
  const user = useSelector(({ auth: { user } }) => user);
  const tableSortParam = useSelector(({ table: { sort } }) => sort);
  const tableSearch = useSelector(({ table: { search } }) => search);
  const tableIsLoading = useSelector(({ ui: { isLoading } }) => isLoading);
  const update = useSelector(({ ui: { update } }) => update);
  const tableScope = useSelector(({ table: { scope } }) => scope);

  const tableData = useSelector(({ table }) => table);
  const currentPage = useSelector(({ table: { page } }) => page);
  const currentRowsPerPage = useSelector(({ table: { rowsPerPage } }) => rowsPerPage);

  useEffect(() => {
    dispatch(thunkActions.getCustomers(currentPage + 1, currentRowsPerPage, tableSearch, tableSortParam, tableScope));
  }, [currentRowsPerPage, currentPage, tableSearch, tableSortParam, dispatch, user, scope, update]);

  return (
    tableIsLoading ? <Box p={2}><LinearProgress color='secondary' /> </Box> : <>
      <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' py={2}>
        <Box display='flex' alignItems='center' >
          <Search
            handleSubmit={(searchValue) => dispatch(actions.table.setSearch({
              keys: ["name", 'id'],
              value: searchValue
            }))}
            placeholder="Search customer name or ID"
          />
        </Box>
        <Box>
          <Button
            color='secondary'
            variant="contained"
            onClick={() => {
              dispatch(actions.ui.setModalOpen({
                key: 'createCustomer',
              }))
            }} startIcon={<PersonAddRounded />}>Create new customer</Button>
        </Box>
      </Box>
      <Grid container spacing={2} >
        <Table headers={customerHeaders} tableData={tableData} />
      </Grid>
    </>
  )
};

export default CustomerScreen;