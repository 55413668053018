import { createTheme } from '@mui/material/styles';
import typography from './typography'
import palette from './palette';

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#7D7C96',
          fontSize: ' 14px',
          paddingRight: '5px',
          backgroundColor: ' #ffffff',
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'none',
          borderRadius: '8px',
          fontSize: '14px',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '0px !important',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#ffffff',
            '&:hover': {
              backgroundColor: ' rgba(0, 0, 0, 0.04)'
            }
          }
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '.MuiSwitch-thumb ': {
            color: '#FBFCFC',
          }
        },
        track: {

          ".MuiSwitch-switchBase.Mui-checked+&": {
            opacity: 1,
            backgroundColor: '#5FD064',
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: 0,
          },
          '&:before': {
            display: 'none'
          },
          boxShadow: 'none',
          padding: 0,
        },
      }
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: 0,
            '.css-o4b71y-MuiAccordionSummary-content': {
              margin: 0,
            },
          },
          padding: 0,
          flexDirection: 'row-reverse',
        },
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '8px 0 0 0',
        },
      }
    }

  }

});
export default theme;