import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme';
import Routes from './services/Routes/Routes';
import './App.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from "dayjs/plugin/customParseFormat";
import isToday from 'dayjs/plugin/isToday';

function App() {
  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(isToday);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        < Routes />
        <ToastContainer
          theme="light"
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          pauseOnFocusLoss
        />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
