import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: { user: {} },
  reducers: {
    setUser(store, action) {
      store.user = action.payload;
    },
    logOut(store) {
      store.user = {};
      store.permissions = null;
    },
  }
});

export const authActions = authSlice.actions;
