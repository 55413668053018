import { actions } from '../slices';

export const closeModal = () => async (dispatch) => {
  dispatch(actions.ui.setModalConfigKey());

  dispatch(actions.ui.setModalOpen(false));
}

export const openModal = (config) => async (dispatch) => {
  dispatch(actions.ui.setModalConfigKey(config));

  dispatch(actions.ui.setModalOpen(true));
}