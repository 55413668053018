import React from 'react';
import { useDispatch } from 'react-redux';
import { EditOutlined } from '@mui/icons-material';
import { formateDate, capitalizeFirstLetter } from '../../utils/helpers';
import { useNavigate } from 'react-router-dom';

import {
	Typography, Box, Tooltip, IconButton, Switch, TableRow, TableCell, Grid,
} from '@mui/material';
import { actions } from '../../redux/slices';
import StatusBox from './StatusBox';


const UsersRow = ({ data }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const handleDeleteUser = (item) => dispatch(
		actions.ui.setModalOpen({
			key: 'deleteUser',
			id: item.id,
		}));

	const handleChange = (item) => {

		return item.status === 'DEACTIVATED' ?
			dispatch(actions.ui.setModalOpen({
				key: 'activateUser',
				id: item.id,
			})) :
			dispatch(actions.ui.setModalOpen({
				key: 'deactivateUser',
				id: item.id,
			}))
	}
	const handleEditUser = (user) => {
		dispatch(actions.ui.setModalOpen({
			additionalData: {
				...user,
			},
			key: 'editUser',
		}))
	};
	return (
		data.map((item) =>
			<TableRow
				key={`${item.id}_row`}
				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			>
				<TableCell style={{ width: '15%' }}>
					<Typography color='text.main' variant='body2'>{item.id}</Typography>
				</TableCell>
				<TableCell style={{ width: '15%' }}>
					<Typography color='text.main' variant='body2'>{item.email}</Typography>
				</TableCell>
				<TableCell style={{ width: '15%' }}>
					<Grid container alignItems='center'>
						<Grid item md={6}><Typography color='text.main' variant='body2' >{capitalizeFirstLetter(item.role.toLowerCase())}</Typography></Grid>
						<Grid item md={6}>
							{item.role !== 'ADMIN' && <Tooltip title='edit' onClick={() => handleEditUser(item)} placement="top" arrow><IconButton><EditOutlined /></IconButton></Tooltip>}
						</Grid>
					</Grid>

				</TableCell>
				<TableCell style={{ width: '15%' }}>
					<Typography color='text.main' variant='body2'>{formateDate(item.created)}</Typography>
				</TableCell>
				<TableCell style={{ width: '15%' }}>
					<Typography color='text.main' variant='body2'>{formateDate(item.updated)}</Typography>
				</TableCell>
				<TableCell style={{ width: '15%' }}>
					<StatusBox status={item.status} />
				</TableCell>
				<TableCell align='center'>
					{item.role !== 'ADMIN' && <Tooltip title={item.status === 'DEACTIVATED' ? 'Activate' : 'Deactivate'} placement="top" arrow>
						<Box >
							<Switch checked={item.status !== 'DEACTIVATED'} size="small" onChange={() => item.status === 'CREATED' ? handleDeleteUser(item) : handleChange(item)} />
						</Box>
					</Tooltip>}
				</TableCell>
			</TableRow>)
	);
}
export default UsersRow;
