import { requiredCampaignFields } from '../../utils/configs/defaultObjects';
import dayjs from 'dayjs'

export const getDifference = (array1, array2) => {
  return array1.filter(object1 => {
    return !array2?.some(object2 => {
      return object1.id === object2.id;
    });
  });
};

export const checkIfDisabled = (errors, sendingTime, curCampaign,) => {
  let isDisabled = false;
  requiredCampaignFields.forEach((it) => {
    if (!curCampaign[it]) {
      isDisabled = true;
    }
  })
  if (errors.length > 0) {
    isDisabled = true;
  }
  if (!sendingTime) {
    isDisabled = true;
  }
  if (sendingTime === 'schedule' && (!curCampaign.scheduleTime || !curCampaign.timeZone)) {
    isDisabled = true;
  }
  if (curCampaign.scheduleTime && sendingTime === 'schedule') {
    if (!dayjs(curCampaign.scheduleTime).isValid()) {
      isDisabled = true;

    }
  }
  if (curCampaign.stopTime && sendingTime === 'schedule') {
    const stopTime = dayjs(curCampaign.stopTime).format("HH:mm");
    const tt = dayjs(`01-01-01 ${stopTime}`);
    if (!dayjs(tt).isValid()) {
      isDisabled = true;
    }
  }
  return isDisabled;
};

export const beforeSave = () => {

}
const sendingTimeInfo = {
  schedule: 'The campaign will be started according to the scheduled date and time.',
  delay: 'The campaign will be started 5 minutes after creation.',
  doNotSend: 'The campaign will be created but not started. You can start the campaign manually or schedule a time to start.',
};
export const getSendingTimeInfo = (sendingTime) => sendingTime ? sendingTimeInfo[sendingTime] : null;
