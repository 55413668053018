import { authSlice, authActions } from './authSlice';
import { sessionSlice, sessionActions } from './sessionSlice';
import { tableSlice, tableActions } from './tableSlice';
import { uiSlice, uiActions } from './uiSlice';

export const slices = {
  auth: authSlice.reducer,
  session: sessionSlice.reducer,
  table: tableSlice.reducer,
  ui: uiSlice.reducer,
}

export const actions = {
  auth: authActions,
  session: sessionActions,
  table: tableActions,
  ui: uiActions
}
