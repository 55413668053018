import React from 'react';
import { useDispatch } from 'react-redux';

import { Typography, Button, Box, Link } from '@mui/material';
import { actions } from '../../redux/slices';
import StartScreenLayout from '../../layouts/StartScreenLayout';

const StartScreen = () => {
  const dispatch = useDispatch();

  const handleLogin = () => {
    dispatch(actions.ui.setModalOpen({
      key: 'login',
    }))
  };
  const handleSignUp = () => {
    dispatch(actions.ui.setModalOpen({
      key: 'signUp',
    }))
  };
  return (
    <StartScreenLayout>
      <Box display='flex' flexDirection='column' >
        <Box pt={2}>
          <Typography variant='h1'>Easily Build, Automate, &#38; Optimize Messaging Campaigns With One Interface.
          </Typography>
        </Box>
        <Box py={4} >
          <Button variant="contained" color='secondary' style={{ minWidth: '167px' }} onClick={handleLogin}>Login</Button>
        </Box>
        {/* <Box>
          <Typography variant='body1'>If you don’t have an account <Link color='secondary' component="button" sx={{ fontSize: '16px' }} onClick={handleSignUp}>Sign up</Link>
          </Typography>
        </Box> */}
        <Box pt={1} display='flex'>
          <Typography style={{ paddingRight: '5px' }} variant='body1'>Contact us </Typography>
          <Typography variant='body1' color='secondary'>technical.support@echovox.com</Typography>
        </Box>
      </Box>
    </StartScreenLayout >
  )
}

export default StartScreen;
