import React, { useState } from 'react';
import { Button, Dialog, DialogContent, Typography, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CheckCircleOutlined } from '@mui/icons-material';

import modalConfig from './config';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux/slices';
import { useEffect } from 'react';

const DialogWindow = () => {
  const dispatch = useDispatch()
  const configKey = useSelector(({ ui: { modalConfigKey } }) => modalConfigKey);
  const open = useSelector(({ ui: { modalOpen } }) => modalOpen);
  const [extraValue, setExtraValue] = useState(null);
  const [modalErrors, setModalErrors] = useState([]);
  const modalLoading = useSelector(({ ui: { modalLoading } }) => modalLoading);
  const navigate = useNavigate();
  const user = useSelector(({ auth: { user } }) => user);
  const {
    text,
    agreeButtonText,
    dispatchAction,
    topContent,
    middleContent,
    columnButtons,
    disabledSaveCondition,
    cancelButtonText,
    bottomContent,
    closeAction,
    notification,
  } = modalConfig[configKey?.key] || {};

  const handleAgree = () => {
    dispatch(dispatchAction({
      id: configKey?.id,
      isUser: user.role === 'USER',
      extraValue: extraValue,
      additionalData: configKey?.additionalData,
    }));
  };
  useEffect(() => {
    if (!open) {
      setExtraValue(null);
      setModalErrors([]);
    }
  }, [open]);

  useEffect(() => {
    setExtraValue(null);
    setModalErrors([]);
  }, [configKey]);

  const handleClose = () => {
    dispatch(actions.ui.setModalClose());
    setExtraValue(null);
    setModalErrors([]);
    if (closeAction) {
      closeAction(navigate)
    }
  };
  const addContent = () => {
    return text || notification ? (
      <DialogContent style={{ paddingLeft: '48px', paddingRight: '48px', paddingBottom: '40px', paddingTop: '64px', width: '500px' }} >
        {notification ?
          <Box display='flex' flexDirection='row' pb='24px' >
            <CheckCircleOutlined color='success' />
            <Typography variant='h4' style={{ paddingLeft: '10px' }}>
              {notification}
            </Typography>
          </Box >
          : <>
            {topContent && <Box>{topContent(configKey)}</Box>}

            <Box pb='32px' sx={{ minWidth: '300px', textAlign: 'center' }}>
              <Typography variant='h4'>{text}</Typography>
            </Box>

            {middleContent && <Box>{middleContent(extraValue, setExtraValue, configKey, modalErrors, setModalErrors)}</Box>}

            <Box display='flex' justifyContent='center' flexDirection={!columnButtons ? 'row' : 'column-reverse'} sx={{ position: 'relative' }}>
              {modalLoading &&
                <Box sx={{ position: 'absolute', width: '100%', textAlign: 'center', top: '8px' }}><CircularProgress color='secondary' /></Box>}
              <Box py={1}>
                <Button size="large" color='primary' sx={!columnButtons ? { minWidth: '100px', marginX: '8px' } : { width: '100%' }} variant={!columnButtons ? 'outlined' : 'text'} onClick={handleClose}>{cancelButtonText ? cancelButtonText : 'Cancel'}</Button>
              </Box>
              <Box py={1}>
                <Button
                  size="large"
                  color='secondary'
                  disabled={(disabledSaveCondition && disabledSaveCondition(extraValue, configKey)) || modalLoading || modalErrors.length > 0}
                  sx={!columnButtons ? { minWidth: '100px', marginX: '8px' } : { width: '100%' }}
                  variant='contained'
                  onClick={handleAgree}>{agreeButtonText}</Button>
              </Box>
            </Box>
            {bottomContent && <Box>{bottomContent(extraValue, setExtraValue, configKey)}</Box>}
          </>}
      </DialogContent>
    ) : <></>
  };

  return (
    <Box  >
      <Dialog
        open={open || false}
        onClose={handleClose}
      >
        {addContent()}
      </Dialog>
    </Box>
  );
}
export default DialogWindow;