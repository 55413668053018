import { Typography } from '@mui/material';
import { sendingTime } from '../../utils/configs/selectOptions';
import { actions } from '../../redux/slices';
import { latin_1_ISO_8859_1, length, onlyLatinLetters } from '../../utils/validation';

const config = {
  name: {
    id: 'name',
    label: 'Campaign name',
    required: true,
    showTooltip: true,
    tooltip: '128 chars maximum.',
    validators: [(str) => length(str, 128),]
  },
  originator: {
    id: 'originator',
    label: 'Sender',
    showTooltip: true,
    tooltip: '11 chars maximum. Only Latin letters allowed.',
    required: true,
    validators: [(str) => onlyLatinLetters(str), (str) => length(str, 11),]
  },
  text: {
    id: 'text',
    label: 'Message text',
    tooltip: '160 chars maximum. Only Latin-1 character set allowed.',
    showTooltip: true,
    required: true,
    multiline: true,
    validators: [(str) => latin_1_ISO_8859_1(str), (str) => length(str, 160),]
  },
  recipients: {
    id: 'consumerListId',
    label: 'Recipients',
    info: <Typography color='primary' sx={{ fontSize: '12px' }} >Select the Recipients List or create a new one.</Typography>,
    required: true,
    optionKeys: { id: 'id', label: 'name' },
    button: {
      action: () => actions.ui.setModalOpen({ key: 'createRecipient' }), name: 'Create list'
    }
  },
  sendingTime: {
    id: 'sendingTime',
    label: 'Sending time',
    required: true,
    optionKeys: { id: 'id', label: 'name' },
    selectOptions: sendingTime,
  },
  msisdn: {
    id: 'msisdn',
    label: 'MSISDN',
    info: <Typography color='primary' sx={{ fontSize: '12px' }}>You can send test SMS. Each attempt is charged.</Typography>,
    required: false,
    button: { action: () => { }, name: 'Test SMS' }
  }
}

export default config;