const palette = {
  background: {
    light: '#FFFFFF',
    default: '#FBFCFC',
    dark: '#33324D',
  },
  primary: {
    main: '#7D7C96',
  },
  secondary: {
    main: '#FD0D37',
    light: '#BCBCBC',
  },
  text: {
    main: '#33324D',
  },
  switcher: {
    main: '#5FD064'
  },
  error: {
    main: '#D32F2F'
  },
  success: {
    main: '#3BE85A'
  }
};

export default palette;