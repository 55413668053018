import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import permissions from '../PermissionWrapper/permissions';

const ProtectPermissionsRoute = ({ children, permId }) => {
  const user = useSelector(({ auth: { user } }) => user);
  if (!permissions[user?.role?.toLowerCase()]?.includes(permId)) {
    return <Navigate to="/" replace />;
  }

  return children;

};

export default ProtectPermissionsRoute;
