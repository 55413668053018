import { actions } from '../slices';
import api from '../../api';

export const createRecipient = (data) => async (dispatch) => {
  dispatch(actions.ui.setModalLoading(true));
  api.createRecipients(data).then(() => {
    dispatch(actions.ui.setModalClose());
    dispatch(actions.table.setPage(0))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalLoading(false))
  })
};

export const editRecipient = (data) => async (dispatch) => {
  let res;
  try {
    dispatch(actions.ui.setModalLoading(true))
    res = await api.editRecipients(data)
  } catch {
    console.log('catch')
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalClose());
  } else {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
  }
};

export const deleteRecipients = (id, isUser) => async (dispatch) => {
  let res;
  try {
    dispatch(actions.ui.setModalLoading(true))
    res = await api.deleteRecipients(id, isUser)
  } catch {
    console.log('catch')
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalClose());
  } else {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
  }
};
