import React from 'react';
import { useDispatch } from 'react-redux';
import { EditOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { formateDate } from '../../utils/helpers';
import PermissionWrapper from '../../services/PermissionWrapper';
import { Typography, Box, Tooltip, IconButton, TableRow, TableCell } from '@mui/material';
import api from '../../api';
import { actions } from '../../redux/slices';

const RecipientRow = ({ data }) => {
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    api.checkLinkedRecipients(id).then((res) => {
      dispatch(actions.ui.setModalOpen({
        id: id,
        key: 'deleteRecipient',
        additionalData: {
          linked: res.data
        }
      }))
    })
  }
  const handleEdit = (item) => {
    api.checkLinkedRecipients(item.id).then((res) => {
      dispatch(actions.ui.setModalOpen({
        additionalData: {
          currentName: item.name,
          linked: res.data,
        },
        key: 'editRecipient',
        id: item.id,
      }))

    })
  }
  return (
    data.map((item) =>
      <TableRow
        key={`${item.id}_row`}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell style={{ width: '10%' }}>
          <Typography color='text.main' variant='body2'>{item.id}</Typography>
        </TableCell>
        <TableCell style={{ width: '20%' }}>
          <Typography color='text.main' variant='body2' >{item.name}</Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <Typography color='text.main' variant='body2' >{item.msisdnCount}</Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <Typography color='text.main' variant='body2'>{formateDate(item.created)}</Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <Typography color='text.main' variant='body2'>{formateDate(item.updated)}</Typography>
        </TableCell>
        <PermissionWrapper permId='recipientTableActions'  >
          <TableCell>
            <Box display='flex' flexDirection='row' justifyContent='space-around'>
              <Tooltip title='Edit' placement="top" arrow>
                <IconButton
                  onClick={(e) => handleEdit(item)}
                ><EditOutlined color='primary' /></IconButton>
              </Tooltip>
              <Tooltip title='Delete' placement="top" arrow>
                <IconButton onClick={(e) => handleDelete(item.id)}><DeleteOutlineOutlined style={{ cursor: 'pointer' }} color='primary' /></IconButton>
              </Tooltip>
            </Box>
          </TableCell>
        </PermissionWrapper>
      </TableRow>)
  );
}
export default RecipientRow;
