import { axiosInstance } from './axios';
import { formatFilters } from '../utils/helpers';

const api = {
  // auth
  login(data) {
    let url = 'auth/login';

    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data,
      },
    });
  },
  sendEmailForCreateUser(data) {
    let url = `/auth/register`;

    return axiosInstance({
      method: 'post',
      url,
      data,
    });
  },
  sendEmailForResetPassword(email) {
    let url = `auth/reset?email=${email}`;

    return axiosInstance({
      method: 'post',
      url,
    });
  },
  resetPassword({ token, id, password }) {
    let url = `auth/reset/confirm/${id}?token=${token}`;
    return axiosInstance({
      method: 'post',
      url,
      data: {
        password,
      },
    });
  },
  activateNewUser({ token, id, password }) {
    let url = `auth/register/confirm/${id}?token=${token}`;

    return axiosInstance({
      method: 'post',
      url,
      data: {
        password,
      },
    });
  },
  //User
  getCurrentUser() {
    let url = 'user/get';

    return axiosInstance({
      method: 'get',
      url,
    });
  },
  updateCurrentUser(data) {
    let url = `user/update`;

    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data,
      }
    });
  },
  updateAdminByID(data) {
    let url = `user/update/${data.customerId}/${data.id}`;
    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data,
      }
    });
  },
  updateUserByID(data) {
    let url = `user/update/${data.id}`;
    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data,
      }
    });
  },
  getUserById({ id, }) {
    let url = `user/get/${id}`;

    return axiosInstance({
      method: 'get',
      url,
    });
  },
  createAdmin(data) {
    let url = `user/create/${data.customerId}`;

    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data
      }
    });
  },
  createUser(data) {
    let url = `user/create`;

    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data
      }
    });
  },
  // Countries
  getCountries() {
    let url = '/country/all';

    return axiosInstance({
      method: 'get',
      url,
    });
  },

  // Campaigns
  getCampaigns({ page, size, filter = null, sort = null, search = null, isUser }) {
    let url = `campaign/get`;
    if (isUser) {
      url = `${url}/user`;
    }
    const data = {
      pageNumber: page,
      pageSize: size,
      filterConditions: filter ? formatFilters(filter) : null,
      sortCriteria: sort,
      search: search
    }
    return axiosInstance({
      method: 'post',
      url,
      data: data,
    });
  },
  getCampaignById(id, isUser) {
    let url = `campaign/${id}/get`;
    if (isUser) {
      url = `${url}/user`;
    }
    return axiosInstance({
      method: 'get',
      url,
    });
  },
  putCampaignById(id, action, isUser) {
    let url = `campaign/${id}/${action}`;
    if (isUser) {
      url = `${url}/user`;
    }
    return axiosInstance({
      method: 'put',
      url,
    });
  },
  testCampaign({ phone, data }) {
    let url = `campaign/test/${phone}`;
    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data,
      },
    });
  },
  deleteCampaignById(id, isUser) {
    let url = `campaign/${id}/delete`;
    if (isUser) {
      url = `${url}/user`;
    }
    return axiosInstance({
      method: 'delete',
      url,
    });
  },
  saveAsDraftCampaign(data) {
    let url = data.id ? `campaign/draft?campaignId=${data.id}` : 'campaign/draft';

    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data,
      },
    });
  },
  createCampaign(data, delay) {
    let url = 'campaign/create';
    if (delay) {
      url = `${url}?delay=5`
    }
    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data,
      },
    });
  },
  updateCampaign(data, isUser, delay) {
    let url = `campaign/${data.id}/update`;
    if (isUser) {
      url = `${url}/user`;
    }
    if (delay) {
      url = `${url}?delay=5`
    }
    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data,
      },
    });
  },

  // Customer
  getCustomerById(id) {
    let url = `customer/get/${id}`;

    return axiosInstance({
      method: 'get',
      url,
    });
  },
  getAllCustomers({ page, size, filter = null, sort = null, search = null }) {
    let url = `customer/filter`;
    const data = {
      pageNumber: page,
      pageSize: size,
      filterConditions: filter,
      sortCriteria: sort,
      search: search
    }
    return axiosInstance({
      method: 'post',
      url,
      data: data,
    });
  },
  activateOrDeactivateCustomer(id) {
    const url = `customer/disable/${id}`;

    return axiosInstance({
      method: 'put',
      url,
    });
  },
  activateOrDeactivateUser(id) {
    const url = `user/disable/${id}`;
    return axiosInstance({
      method: 'put',
      url,
    });
  },
  updateCustomerById(data) {
    const url = `customer/update/${data.id}`;

    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data,
      },
    });
  },
  updateCustomerPWD(customerId, data) {
    const url = `customer/reset/${customerId}`;

    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data,
      },
    });
  },
  createCustomer(data) {
    const url = `customer/create`;

    return axiosInstance({
      method: 'post',
      url,
      data: {
        ...data,
      },
    });
  },
  // Recipients
  getRecipients({ page, size, isUser }) {
    let url = `consumer/all`;
    if (isUser) {
      url = `${url}/user`;
    }
    if (size) {
      url = `${url}/${page}/${size}`
    }
    return axiosInstance({
      method: 'get',
      url,
    });
  },
  createRecipients({ name, file }) {
    let url = `consumer/create?name=${name}`;
    const data = new FormData();
    data.append('file', file);
    return axiosInstance({
      method: 'post',
      url,
      headers: {
        'Content-type': 'multipart/form-data',
      },
      data,
    });
  },
  editRecipients({ id, name, file, isUser }) {
    let url = `consumer/${id}/update/file`;
    if (isUser) {
      url = `${url}/user`;
    }
    if (name) {
      url = `${url}?name=${name}`
    }
    const data = new FormData();
    if (file) {
      data.append('file', file);
    }
    return axiosInstance({
      method: 'post',
      url,
      headers: {
        'Content-type': 'multipart/form-data',
      },
      data,
    });
  },
  checkLinkedRecipients(id) {
    let url = `consumer/${id}/get`;
    return axiosInstance({
      method: 'get',
      url,
    });
  },
  deleteRecipients(id, isUser) {
    let url = `consumer/${id}/delete`;
    if (isUser) {
      url = `${url}/user`;
    }
    return axiosInstance({
      method: 'delete',
      url,
    });
  },
  getAllUsersForCurrentCustomer({ page, size, filter = null, sort = null, search = null }) {
    let url = `/user/filter/customer`;
    const data = {
      pageNumber: page,
      pageSize: size,
      filterConditions: filter,
      sortCriteria: sort,
      search: search
    }
    return axiosInstance({
      method: 'post',
      url,
      data: data,
    });
  },

}

export default api;