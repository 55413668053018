import React, { useState } from 'react';
import {
  Button,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import api from '../../api';
import { Input } from '../../components/Inputs';
import { actions } from '../../redux/slices';
import DetailPageLayout from '../../layouts/DetailPageLayout';
import { updateCurrentUser } from '../../redux/actions/auth';
import { length } from '../../utils/validation';

const UserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth: { user } }) => user);
  const [name, setName] = useState(user.name || '')
  const [errors, setErrors] = useState([]);

  const handleUpdateUser = () => {
    dispatch(updateCurrentUser({ ...user, name }))
  }
  const handleChangePassword = () => {
    dispatch(actions.ui.setModalOpen({
      key: 'changePassword',
      additionalData: {
        email: user?.email
      }
    }))
  };
  return (
    <DetailPageLayout sectionName='User profile' handleSave={handleUpdateUser} saveIsDisabled={(name && user.name === name) || errors.length > 0}>
      <Input
        id='name'
        showTooltip={true}
        errors={errors}
        setErrors={setErrors}
        tooltip='128 chars maximum.'
        validators={[(str) => length(str, 128)]}
        defaultValue={name}
        value={null}
        label='User name'
        width='60%'
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        label='Account email'
        width='60%'
        defaultValue={user?.email}
        idDisabled={true}
        value={null}
      />
      <Button variant='text' color='secondary' onClick={handleChangePassword}>Change password</Button>

    </DetailPageLayout >
  )

}


export default UserProfile;