import { actions } from '../slices';
import { toast } from 'react-toastify';
import api from '../../api';
import paths from '../../services/Routes/paths';

export const handleCampaignAction = (id, action, isUser, additionalData) => async (dispatch) => {
  let res;
  dispatch(actions.ui.setModalLoading(true))
  try {
    if (action === 'withDelay') {
      res = await api.updateCampaign(additionalData, isUser, true);
    } else {
      res = await api.putCampaignById(id, action, isUser);
    }
  } catch {
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalClose());
  } else {
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
  }
};

export const deleteCampaign = (id, setPushUrl, isUser) => async (dispatch) => {
  let res;
  try {
    dispatch(actions.ui.setModalLoading(true))
    res = await api.deleteCampaignById(id, isUser)
  } catch {
  }
  if (res) {
    if (setPushUrl) {
      dispatch(actions.ui.setPushUrl(setPushUrl));
    }
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalClose());
  } else {
    dispatch(actions.ui.setUpdate())
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
  }
};

export const sendTestSMS = (phone, data) => async (dispatch) => {
  let res;
  try {
    dispatch(actions.ui.setModalLoading(true))
    res = await api.testCampaign({ phone, data })
  } catch {
  }
  if (res) {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
    dispatch(actions.ui.setModalOpen({
      key: 'sendTestSMSSuccess',
      id: res.data
    }))

  } else {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
  }
};
export const checkTestCampaign = (id) => async (dispatch) => {
  dispatch(actions.table.setSearch({
    keys: ['id'],
    value: id
  }))
  window.open(`${window.location.origin}/campaigns`, '_blank');
};

export const campaignSaveAsDraft = (additionalData) => async (dispatch) => {
  let res;
  dispatch(actions.ui.setModalLoading(true))
  try {
    res = await api.saveAsDraftCampaign({ ...additionalData, scheduleTime: null, stopTime: null })

  } catch {
  }
  if (res) {
    if (!additionalData.id) {
      dispatch(actions.ui.setPushUrl(`${paths.campaigns}/${res.data.id}`));
    } else {
      dispatch(actions.ui.setPushUrl(`update`));
    }
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
  } else {
    dispatch(actions.ui.setModalLoading(false))
    dispatch(actions.ui.setModalClose());
  }
};
